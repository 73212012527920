

export const getSiteNameByBrand = (isBirdviewBrand?: boolean) => {
    return isBirdviewBrand === undefined ?
        "Customer Portal"
        : isBirdviewBrand ? "Birdview Customer Portal" : "Easy Projects Customer Portal";
}


export const getPageTitle = (title?: string, isBirdviewBrand?: boolean) => {

    return title ? `${title} | ${getSiteNameByBrand(isBirdviewBrand)}` : `${getSiteNameByBrand(isBirdviewBrand)}`;
}


export const isInhouseClient = (type?: number) => {
    return type === 1 || type === 2;
}

export const isHostedClient = (type?: number) => {
    return type === 4;
}

export const isInhouseSubscriptionClient = (type?: number) => {
    return type === 9;
}

export const isExtraStorage = (type?: number) => {
    return type === 7;
}
export const isAddIns = (type?: number) => {
    return type === 10;
}
export const hasRenewOptions = (type: number) => {
    const renewedTypes = [2, 4, 7, 9, 10, 11, 12, 14];
    return renewedTypes.includes(type);
}

export const showUpdatePlanButton = (edition: number) => {
    //exclude lite | ps_lite edition
    
    return  edition !== 2 && edition !== 7;
    
}


import { BillingModel, ChangePasswordParams, Client, ClientModel, CurrentUser, LoginParams } from '../types';
import { handleError, handleResponse } from './request';

type RequestResult<T> = {
    model?: T;
    error?: string;
}
type SuccessfulModel = {
    successful: boolean;
}

export const apiLogin = (payload: LoginParams): RequestResult<CurrentUser> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    return fetch('/api/new/signin', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (user: CurrentUser) => {
                return { model: user };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<CurrentUser>;
};

export const apiLogout = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/new/signout', requestOptions).then(handleResponse, handleError);
};

export const apiCurrentUser = (): Promise<CurrentUser> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/new/currentUser', requestOptions).then(handleResponse, handleError);
};

export const apiResetPassword = (email: string) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    return fetch('/api/new/passwordRecovery', requestOptions).then(handleResponse, handleError);
};

export const apiChangePassword = (payload: ChangePasswordParams): RequestResult<SuccessfulModel> => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    return fetch('/api/new/changePassword', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: SuccessfulModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<SuccessfulModel>;
};

export const apiGetClientModel = (): RequestResult<ClientModel> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/new/clientModel', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: ClientModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<ClientModel>;
};


export const apiGetBillingModel = (): RequestResult<BillingModel> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/new/billingModel', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: BillingModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<BillingModel>;

};

export const apiUpdateCompanyInformation = (data: Client): RequestResult<ClientModel> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch('/api/new/updateCompanyProfile', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: ClientModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<ClientModel>;
}

export const apiGetCountries = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch('/api/new/GetCountryList', requestOptions).then(handleResponse, handleError);
}

export const apiUpdateBillingInfoAction = (data: any): RequestResult<BillingModel> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch('/api/new/updateBillingInfo', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: BillingModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<BillingModel>;
}

export const apiUpdateEmailNotifications = (data: Client): RequestResult<SuccessfulModel> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            isSubscribed: data.IsSubscribed,
            newSubscribers: data.Subscribers,
        })
    };
    return fetch('/api/new/updateEmailNotifications', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (data: SuccessfulModel) => {
                return { model: data };
            },
            (error: string) => {
                return { error: error };
            }
        ) as RequestResult<SuccessfulModel>;
}
export const apiDownloadLiicense = (data: string): any => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            version: data,
        })
    };
    return fetch('/api/new/downloadLicense', requestOptions)
        .then(handleResponse, handleError)
        .then(
            (blob: Blob) => {
                return { model: blob };
            },
            (error: string) => {
                return { error: error };
            }
        );
}
import { Button, Col, Divider, Drawer, Row, Typography } from "antd"
import moment from "moment";
import { Payment } from "../../types";
import { exportHtmlToPdf } from "../../utils/exportToPdf";
import { BrandLogo } from "../layout/BrandLogo";
import { PaymentItems } from "./paymentItems";
import styles from './style.module.scss';

type Props = {
    data?: Payment;
    onClose: () => void;
    showDetails: boolean;
}

export const PaymentDetails: React.FC<Props> = ({ onClose, showDetails, data }) => {

    const handleClose = () => {
        onClose();
    }

    const exportToPdf = () => {
        const element = document.querySelector<HTMLElement>("#container");
        if (element)
            exportHtmlToPdf(element, `Receipt_${data?.OrderNumber}.pdf`);
    }
    return (
        <>
            {data && <div>
                <Drawer className={styles.r_drawer}
                    title="Payment Details"
                    width={'50%'}
                    maskClosable={false}
                    onClose={handleClose}
                    visible={showDetails}
                >
                    <div style={{ padding: 20 }} id="container">
                        <Row gutter={16} align={"top"}>
                            <Col span={8} >
                                <BrandLogo />
                            </Col>
                            <Col span={4} >

                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Typography.Title level={4}>Logic Software Inc.</Typography.Title>
                                <p>
                                    446 Autumn Hill Blvd<br />
                                    Vaughan ON L4J 9B8<br />
                                    Canada<br />
                                    <a href="tel:14169079944">+1 416-907 9944</a><br />
                                    <a href="mailto:accounting@birdviewpsa.com">accounting@birdviewpsa.com</a><br />
                                    <a href="https://birdviewpsa.com">https://birdviewpsa.com</a><br />
                                    HST Registration No.: 861513588 RT0001<br />
                                </p>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="center">
                            <h2>Receipt / Paid Invoice<br />
                                #{data.OrderNumber}</h2>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={8} >
                                <div><strong>BILL TO</strong></div>
                                <div>
                                    {data.BillingName}<br />
                                        <strong>{data.Company}</strong> <br />
                                        {data.BillingAddress?.AddressInfo}
                                </div>
                            </Col>
                            <Col span={8} >
                            <div><strong>SHIP TO</strong></div>
                                <div>
                                    {data.ShippingName}<br />
                                        <strong>{data.Company}</strong> <br />
                                        {data.ShippingAddress?.AddressInfo}
                                </div>
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                                <div><strong>DATE</strong></div>
                                <div>{moment(data.OrderDate).format("MMM DD, YYYY")}</div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={24}>
                                <PaymentItems payment={data} />
                            </Col>
                        </Row>
                        <Row gutter={16} justify="end">
                            <span style={{ marginRight: 20 }}>Thank you!</span>
                        </Row>
                    </div>


                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            right: 50,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 80px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={exportToPdf} style={{ marginRight: 8 }} type="primary">
                            Export to Pdf
                        </Button>
                        <Button onClick={handleClose} style={{ marginRight: 8 }}>
                            Close
                        </Button>
                    </div>
                </Drawer>
            </div>
            }
        </>
    )
}
import { Divider, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Subscription } from "../../types";
import { hasRenewOptions, isAddIns } from "../../utils";
const { Text } = Typography;

type Props = {
    loading: boolean;
    rowKey: string;
    items: Subscription[];
}

export const ActiveThirdPartySubscriptions: React.FC<Props> = ({ loading, items, rowKey }) => {
    const columns: ColumnsType<Subscription> = [
        {
            title: "Created on",
            dataIndex: 'FirstOrderDate',
            key: 'FirstOrderDate',
            width: 150,
            render: val => <>{moment(val).format('MM/DD/YYYY')}</>,
        },

        {
            title: "Users",
            dataIndex: 'NumberOfUsers',
            key: 'NumberOfUsers',
            align: "right",
            width: 150,
            render: (text, record) => {
                if (isAddIns(record.LicenseType)) {
                    return <> {record.NumberOfUsers}</>
                }
            },
        },
        {
            title: "Product",
            dataIndex: 'ProductName',
            key: 'ProductName',
            width: 300,
            render: (text, record) => {
                return (
                    <> {record.ProductName} {record.LicenseRenewal === 3 && <Text style={{ color: "blue" }}>(FREE)</Text>}</>
                );
            },
        },
        {
            title: "Last Payment",
            dataIndex: 'LastPaymentDate',
            key: 'LastPaymentDate',
            width: 150,
            render: (text, record) => {
                return (
                    <>  {record.LicenseRenewal !== 3 && <>{record.LastPaymentDate ? moment(record.LastPaymentDate).format('MM/DD/YYYY') :""}</>} </>
                );
            },
        },
        {
            title: "Term (months)",
            dataIndex: 'ExpirationTerm',
            key: 'ExpirationTerm',
            align: "right",
            render: (text, record) => {
                if (hasRenewOptions(record.LicenseType) && record.LicenseRenewal !== 3) {
                    return <>{record.ExpirationTerm}</>
                }
                return <></>
            },
        },

        {
            title: "Renews on",
            dataIndex: 'ExpireDate',
            key: 'ExpireDate',
            render: (text, record) => {
                if (hasRenewOptions(record.LicenseType) && record.LicenseRenewal !== 3) {
                    const expire = moment(record.ExpireDate).format('MM/DD/YYYY');
                    const cancelledAt = moment(record.CancelledAt || record.ExpireDate).format('MM/DD/YYYY');
                    const today = moment().format('MM/DD/YYYY');
                    if (record.IsStripeSubscriptionActive) {
                        if (moment(expire) < moment(today)) {
                            return <span style={{ color: "red" }} > {expire}</span >
                        }
                        return <span> {expire}</span >
                    } else {
                        return <span style={{ color: "darkred" }}> Cancelled at {cancelledAt}</span >
                    }
                }
                return <></>
            },
        },
    ];

    return (
        <>
            <Divider orientation="left" >Active 3rd party Subscriptions</Divider>
            <Table<Subscription>
                bordered
                loading={loading}
                dataSource={items}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
            />


        </>);
}
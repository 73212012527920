import { Form, Row, Col, Divider, Input, FormInstance } from 'antd';
import { useState } from 'react';
import { CountryDropdown, StateDropdown } from '../components/CountryStates';
import { Address } from '../../types';

type Props = {
    address:string;
    form: FormInstance<any>;
}

export const AddressForm: React.FC<Props> = ({ form, address }) => {
    const getAddress = () => {
        return form.getFieldValue(`${address}`);
    }
    const [, countryChanged] = useState(false);

    const changeCountry = (value: string) => {
        countryChanged(true);

        form.setFieldsValue({
            [address]: {
                AddressCountry: value,
                AddressState: "",
            }
        });
    };

return <>

    <Row gutter={16}>
        <Col span={12}>
            <Form.Item label='Country:' name={[`${address}`, 'AddressCountry']}>
                <CountryDropdown
                    priorityOptions={["US", "CA", "UK"]}
                    value={getAddress()?.AddressCountry}
                    valueType={'short'}
                    labelType={'full'}
                    onChange={changeCountry}
                />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label='State/Province:' name={[`${address}`,  'AddressState']} >
                <StateDropdown
                    value={getAddress()?.AddressState}
                    country={getAddress()?.AddressCountry}
                    defaultOptionLabel="Select state / province"
                    countryValueType={'short'}
                    valueType={'short'}
                    labelType={'full'}
                    customOptions={[]}
                />
            </Form.Item>
        </Col>
    </Row>
    <Row gutter={16}>
        <Col span={12}>
            <Form.Item label='Address 1:' name={[`${address}`, 'AddressLine1']} >
                <Input placeholder="Address line 1" />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item label='Address 2:' name={[`${address}`, 'AddressLine2']} >
                <Input placeholder="Address line 2" />
            </Form.Item>
        </Col>
    </Row>
    <Row gutter={16}>
        <Col span={12}>
            <Form.Item label='City:' name={[`${address}`,  'AddressCity']} >
                <Input placeholder="City" />
            </Form.Item>

        </Col>
        <Col span={12}>
            <Form.Item label='Zip/Postal Code:' name={[`${address}`, 'AddressZip']} >
                <Input placeholder="Zip/Postal Code" />
            </Form.Item>
        </Col>
    </Row>

</>
}
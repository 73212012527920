import { Alert, Button, PageHeader, Row, Col, Tabs } from 'antd';
import * as React from 'react';
import { useAppDispatch, useAppState } from '../../stores';
import { getClientModelAsync } from '../../stores/portalReducer';
import { isInhouseClient, showUpdatePlanButton } from '../../utils';
import { ActiveSubscriptions } from './activeSubscriptions';
import { ActiveThirdPartySubscriptions } from './activeThirdPartySubscriptions';
import { CancelledSubscriptions } from './cancelledSubscriptions';
import { CancelledThirdPartySubscriptions } from './cancelledThirdPartySubscriptions';
import { ContactSales } from './contactSales';
import { Summary } from './summary';

const { TabPane } = Tabs;

const useClientSubscriptionsData = () => {
    const model = useAppState(state => state.clientModel);
    return {
        activeLicenses: model?.ActiveLicenses || [],
        cancelledLicenses: model?.CancelledLicenses || [],
        activeThirdPartyLicenses: model?.ActiveThirdPartyLicenses || [],
        cancelledThirdPartyLicenses: model?.CancelledThirdPartyLicenses || [],
        isSalesAssisted: model?.IsSalesAssisted || false,
        isBirdview: model?.IsBirdview || false,
        stripeCustomerId: model?.Client.StripeCustomerId,
        clientLicenseType: model?.ClientLicenseType,
        isBirdviewAutomationEnabled: model?.IsBirdviewAutomationEnabled,
        isCancelled: model?.IsCancelled,
    };
}

const Subscriptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const model = useAppState(state => state.clientModel);
    const loading = !model;

    React.useEffect(() => {
        if (!model) {
            dispatch(getClientModelAsync());
        }
    }, [model, dispatch]);

    const { activeLicenses, cancelledLicenses,
        activeThirdPartyLicenses, cancelledThirdPartyLicenses,
        isSalesAssisted, isBirdviewAutomationEnabled,
        stripeCustomerId, clientLicenseType, isCancelled } = useClientSubscriptionsData();

    const showSubscriptions = activeLicenses.length > 0 || cancelledLicenses.length > 0;
    const showThirdPartySubscriptions = activeThirdPartyLicenses.length > 0 || cancelledThirdPartyLicenses.length > 0;
    const showBothTabs = showSubscriptions && showThirdPartySubscriptions;

    const addLicensesLink = () => {
        return stripeCustomerId ?
            `/payment/addMoreLicenses?accountId=${stripeCustomerId}` :
            '/payment/addMoreLicenses';
    }
    const updatePlanLink = () => {
        return '/payment/plans';
    }

    const purchaseAutomationLink = () => {
        return '/payment/automationPurchase';
    }
    return (
        <>
            <PageHeader title={isInhouseClient(clientLicenseType) ? "My Licenses" : "My Subscriptions"} />
            <div style={{ padding: '0 24px 24px', minHeight: 20 }}>
                <Summary loading={loading} />
                {isInhouseClient(clientLicenseType) &&
                    <Alert
                        message="Gold Support Discountinued"
                        description={<>We’re sorry, but as of August 1, 2018 Perpetual licenses and Gold Support packages are no longer available.
                            <br />
                            <br />
                            Please contact us at <a href="sales@birdviewpsa.com" target="_blank">sales@birdviewpsa.com</a> or  <a href="tel:18882619878">+1 (888) 261-9878</a> to learn how to switch your Perpetual licenses to the Subscription licenses.</>}
                        type="warning"
                        showIcon
                    />}
                {!loading && <>
                    {!isInhouseClient(clientLicenseType) &&
                        <Row justify="space-around" align="middle" style={{ marginBottom: (showBothTabs ? 16 : 0) }}>
                            <Col flex="1 0 auto" style={{ paddingTop: 20 }}>
                                <div>
                                    To decrease the number of licenses or to cancel your account, please <ContactSales />
                                </div>
                                {!isCancelled && <>
                                    {!isSalesAssisted &&
                                        <Button type="primary" href={addLicensesLink()} style={{ marginTop: 20 }}>
                                            Add more licenses
                                        </Button>
                                    }

                                    {showUpdatePlanButton(model.Edition) &&
                                        <Button type="ghost" href={updatePlanLink()} style={{ marginTop: 20, marginLeft: 10 }}>
                                            Update plan
                                        </Button>
                                    }
                                    {isBirdviewAutomationEnabled !== true && <Button type="primary" ghost href={purchaseAutomationLink()} style={{ marginTop: 20, marginLeft: 10 }}>
                                        Purchase Automation
                                    </Button>
                                    }</>
                                }
                            </Col>
                        </Row>
                    }
                </>}
                <Tabs defaultActiveKey="subscriptions" >
                    {showSubscriptions &&
                        <TabPane tab={showBothTabs ? "Subscriptions" : ""} key="subscriptions" >
                            {activeLicenses.length > 0 && <ActiveSubscriptions
                                items={activeLicenses}
                                rowKey={"Id"}
                                loading={loading}
                                clientLicenseType={clientLicenseType}
                            />
                            }
                            {cancelledLicenses.length > 0 && <CancelledSubscriptions
                                items={cancelledLicenses}
                                rowKey={"Id"}
                                loading={loading}
                                clientLicenseType={clientLicenseType}
                            />}
                        </TabPane>
                    }

                    {showThirdPartySubscriptions &&
                        <TabPane tab={showBothTabs ? "3rd party subscriptions" : ""} key="thirdPartySubscriptions" >
                            {activeThirdPartyLicenses.length > 0 && <ActiveThirdPartySubscriptions
                                items={activeThirdPartyLicenses}
                                rowKey={"Id"}
                                loading={loading}
                            />
                            }
                            {cancelledThirdPartyLicenses.length > 0 && <CancelledThirdPartySubscriptions
                                items={cancelledThirdPartyLicenses}
                                rowKey={"Id"}
                                loading={loading}
                            />}
                        </TabPane>
                    }
                </Tabs>
            </div>
        </>
    )
}
export default Subscriptions;
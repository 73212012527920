import * as html2pdf from 'html2pdf.js';

export const exportHtmlToPdf = (element: HTMLElement, filename: string) => {

    const options = {
        margin: [0, 0.3],
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {},
        jsPDF: {
            unit: "in",
            format: "letter",
            orientation: "portrait",
        },
    };

    html2pdf().set(options).from(element).save();
};
import { Form, Row, Col, Divider, Input, FormInstance } from 'antd';
import { AddressForm } from './addressForm';

type Props = {
    contact: string;
    form: FormInstance<any>;
}

export const ContactForm: React.FC<Props> = ({ form, contact }) => {

    return <>
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item label='First name:' name={[`${contact}`, 'FirstName']}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: 'First name is required!',
                        },
                    ]}
                >
                    <Input placeholder="First name" />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label='Last name:' name={[`${contact}`, 'LastName']}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: 'Last name is required!',
                        },
                    ]}
                >
                    <Input placeholder="Last name" />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="Email" name={[`${contact}`, 'Email']}
                    rules={[{
                        type: 'email',
                        message: 'The input is not valid e-mail!',
                    },
                    {
                        required: true,
                        message: 'E-mail is required!'
                    },
                    ]}>
                    <Input placeholder="E-mail" />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item label='Phone:' name={[`${contact}`, 'Phone']}>
                    <Input placeholder="Phone" />
                </Form.Item>
            </Col>
            <Col span={12}>

                <Form.Item label='Title:' name={[`${contact}`, 'Title']}>
                    <Input placeholder="Title" />
                </Form.Item>
            </Col>
        </Row>
    </>
}
import { useAppState } from '../../stores';

export const ContactSales = () => {
    const { currentUser } = useAppState(state => state);

    return (
        <>
            {currentUser?.IsBirdviewBrand ?
                <a href="https://www.easyprojects.net/company/contact/?p=bv" target="_blank" title="Birdview" rel="noreferrer">
                    contact our sales department.
                </a> :
                <a href="https://www.easyprojects.net/company/contact" target="_blank" title={"Easy Projects"} rel="noreferrer">
                    contact our sales department.
                </a>}
        </>

    );
}
import React, { useState } from 'react';
import { Card, Collapse, Descriptions, Divider, Skeleton, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { EditOutlined } from '@ant-design/icons';
import visa from '../../assets/cards/visa.svg';
import masterCard from '../../assets/cards/mastercard.svg';
import amex from '../../assets/cards/amex.svg';
import { StripeCard } from "../../types";
import { UpdateBillingInfoModal } from './UpdateBillingInfo';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppState } from '../../stores';

const { Meta } = Card;
const { Panel } = Collapse;

type Props = {
    loading: boolean;
    card?: StripeCard;
}

export const PaymentMethods: React.FC<Props> = ({ card, loading }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const { currentUser } = useAppState(state => state);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const updateCardExtra = () => (
        <EditOutlined style={{ marginLeft: 50 }}
            title="Update card"
            onClick={event => {
                showModal();
                event.stopPropagation();
            }}
        />
    );

    const getBrandIcon = (brand: string) => {
        switch (brand) {
            case "Visa": return visa;
            case "MasterCard": return masterCard;
            case "American Express": return amex;
        }
    }
    const getTitle = (brand: string, last4: string, isExpired: boolean) => {
        return <>
            {brand}  **** {last4}   {isExpired && (<Tag>Expired</Tag>)}
        </>
    }
    const cardHeader = (card: StripeCard) => {
        const date = new Date(card.ExpYear, card.ExpMonth, 1);
        var expiryDate = new Date(date.setMonth(date.getMonth() + 1));
        const isExpired = expiryDate < new Date();

        return <Meta
            style={{ display: "flex", alignItems: "center" }}
            avatar={<Avatar src={getBrandIcon(card.Brand)} shape={"square"} />}
            title={getTitle(card.Brand, card.Last4, isExpired)}
            description={`Expires ${card.ExpMonth} / ${card.ExpYear}`}
        />
    }

    const stripePromise = loadStripe(currentUser?.StripeApiKey || "");

    return (
        <>
            {card &&
                <>
                    <h3>Payment methods</h3>
                    <Skeleton loading={loading} >
                        <Divider />
                        <Collapse bordered={false} ghost >
                            <Panel header={cardHeader(card)} key="1" extra={updateCardExtra()}>
                                <>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="Card Holder">{card.Name}</Descriptions.Item>
                                        <Descriptions.Item label="Number">**** **** **** {card.Last4}</Descriptions.Item>
                                        <Descriptions.Item label="Expires"> {card.ExpMonth} / {card.ExpYear}</Descriptions.Item>
                                        <Descriptions.Item label="Type">{card.Brand} {card.Funding} card</Descriptions.Item>
                                        <Descriptions.Item label="Address">
                                            {card.AddressLine1} <br />
                                            {card.AddressLine2 !== "" && <>{card.AddressLine2} <br /></>}
                                            {card.AddressCity}, {card.AddressState}, {card.AddressZip}, {card.AddressCountry}
                                        </Descriptions.Item>

                                    </Descriptions>
                                </>
                            </Panel>
                        </Collapse>
                        <Divider />
                    </Skeleton>
                    <Elements stripe={stripePromise}>
                        <UpdateBillingInfoModal
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            onOk={handleOk}
                            accountId={card.CustomerId}
                        />
                    </Elements>

                </>
            }
        </>
    );
}




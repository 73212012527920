import { useAppState } from '../../stores';
import styles from './style.module.scss';

export const SiteLogo = () => {
    const { currentUser } = useAppState(state => state);

    return (
        <>
            {currentUser?.IsBirdviewBrand ?
                <a href="https://www.easyprojects.net/birdview-psa/" target="_blank" title="Birdview" rel="noreferrer">
                    <div className={styles.menu_logo_bv} />
                </a> :
                <a href="https://www.easyprojects.net" target="_blank" title={"Easy Projects"} rel="noreferrer">
                    <div className={styles.menu_logo_ep} />
                </a>}
        </>
    );
}
import { Layout, Menu } from 'antd';
import {
    MailOutlined,
    MessageOutlined,
    UserOutlined,
    CreditCardOutlined,
    CustomerServiceOutlined,
} from '@ant-design/icons';

import styles from './style.module.scss';
import { useHistory, useLocation } from 'react-router';
import { SiteLogo } from './SiteLogo';
import { useAppState } from '../../stores';
import { isInhouseClient } from '../../utils';

const { Sider } = Layout;


export type SiderMenuProps = {
    collapsed: boolean;
    handleOnCollapse: () => void;
};

const SiderMenu = ({ handleOnCollapse, collapsed }: SiderMenuProps) => {
    const theme = 'light';
    const location = useLocation();
    const history = useHistory();
    const { currentUser } = useAppState(state => state);
    const isInhousePerpetual = isInhouseClient(currentUser?.ClientLicenseType);

    const handleSiderMenuClick = (action: { key: any; }) => {
        console.log('menu:', action);
        switch (action.key) {
            case '/portal':
            case '/portal/companyProfile':
            case '/portal/billing':
            case '/portal/manageSubscriptions':
                history.push(action.key);
                break;
            case 'support': break;
            default:
                history.push('/portal');
        }
    };

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="80"
            onCollapse={handleOnCollapse}
            collapsed={collapsed}
            width="256"
            theme={theme}
        >
            <SiteLogo />
            <Menu mode="inline" theme={theme}
                onClick={handleSiderMenuClick}
                defaultSelectedKeys={["/portal"]}
                selectedKeys={[location.pathname]}
            >
                <Menu.Item key="/portal">
                    <MailOutlined className={styles.menu_icon} />
                    <span>{isInhousePerpetual ? <>Licenses</> : <>Subscriptions</>}</span>
                </Menu.Item>
                <Menu.Item key="/portal/companyProfile">
                    <UserOutlined className={styles.menu_icon} />
                    <span>Company Profile</span>
                </Menu.Item>
                <Menu.Item key="/portal/billing">
                    <CreditCardOutlined className={styles.menu_icon} />
                    <span>Billing</span>
                </Menu.Item>
                <Menu.Item key="/portal/manageSubscriptions">
                    <MessageOutlined className={styles.menu_icon} />
                    <span>Email Notifications</span>
                </Menu.Item>
                <Menu.Item key="support">
                    <a href="https://help.easyprojects.net" target="_blank" rel="noreferrer">
                        <CustomerServiceOutlined className={styles.menu_icon} />
                        <span> Support</span>
                    </a>
                </Menu.Item>
            </Menu>
        </Sider >
    );
}

export default SiderMenu;
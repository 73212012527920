import { PageHeader } from 'antd';
import * as React from 'react';
import { EmailSettings } from './emailSettings';


class EmailNotifications extends React.Component {
    public render() {
        return (
            <>
                <PageHeader title="Email notifications" />
                <div style={{ padding: '0 24px 24px', minHeight: 20 }}>
                    <EmailSettings />
                </div>

            </>
        )
    }
}
export default EmailNotifications;
import { useAppState } from '../../stores';

import bv_logo from '../../assets/bv-logo.svg';
import ep_logo from '../../assets/ep-logo.svg';

export const BrandLogo = () => {
    const { currentUser } = useAppState(state => state);

    return (
        <>
            {currentUser?.IsBirdviewBrand ?
                <a href="https://birdviewpsa.com" target="_blank" title="Birdview" rel="noreferrer">
                    <img src={bv_logo} height={64} alt="Birdview" />
                </a> :
                <a href="https://www.easyprojects.net" target="_blank" title="Easy Projects" rel="noreferrer">
                    <img src={ep_logo} height={84} alt="Easy Projects" />
                </a>}
        </>
    );
}
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { message, Modal } from 'antd';
import { apiChangePassword, apiCurrentUser, apiDownloadLiicense, apiGetBillingModel, apiGetClientModel, apiGetCountries, apiLogin, apiLogout, apiResetPassword, apiUpdateBillingInfoAction, apiUpdateCompanyInformation, apiUpdateEmailNotifications } from '../api/portal';
import { ChangePasswordParams, Client, ClientModel, CurrentUser, LoginParams, PortalState } from '../types';
import { getGlobalState } from '../utils/getGlobal';
import { Country } from '../pages/components/CountryStates';
import { useHistory } from 'react-router';


const initialState: PortalState = {
    currentUser: undefined,
    clientModel: undefined,
    billingModel: undefined,
    countries: [],
    isCountriesLoading: false,
    ...getGlobalState(),
};

const portalSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setPortalState(state, action: PayloadAction<Partial<PortalState>>) {
            Object.assign(state, action.payload);
        }
    }
});

export const { setPortalState } = portalSlice.actions;

export default portalSlice.reducer;

export const loginAsync = (payload: LoginParams) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiLogin({ ...payload });
        if (error) {
            message.error(error);
            return false;
        }

        if (model) {
            if (model.Role === 6) { // affiliate user
                window.location.href = window.location.origin + "/affiliate";
                return false;
            }
            dispatch(
                setPortalState({
                    currentUser: model
                })
            );

            return true;
        }
    }
};

export const logoutAsync = () => {
    return async (dispatch: Dispatch) => {
        return await apiLogout()
            .then(() => {
                localStorage.clear();
                dispatch(
                    setPortalState({
                        currentUser: undefined
                    })
                );
                return true;
            });
    };
};

export const getCurrentUserAsync = (callback?: (user: CurrentUser) => void) => {
    return async (dispatch: Dispatch) => {
        const user = await apiCurrentUser();
        dispatch(
            setPortalState({
                currentUser: user,
            })
        );
        callback?.(user);
    };
};

export const resetPasswordAsync = (email: string) => {
    return async () => {
        return await apiResetPassword(email)
            .then(
                (res) => {
                    message.success("You will receive an email with the password recovery email soon");
                    return res;
                },
                error => {
                    Modal.error({
                        centered: true,
                        title: 'Recover password failed',
                        content: error,
                    });
                    return false;
                });

    };
};

export const changePasswordAsync = (payload: ChangePasswordParams) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiChangePassword({ ...payload });
        if (error) {
            return error;
        }
        if (model) {
            message.success("Password changed successfully");
            return true;
        }
    };

};

export const getClientModelAsync = (callback?: (model: ClientModel) => void) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiGetClientModel();
        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            dispatch(
                setPortalState({
                    clientModel: model,
                })
            );
            callback?.(model);
            return true;
        }
    };
};

export const getBillingModelAsync = () => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiGetBillingModel();
        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            dispatch(
                setPortalState({
                    billingModel: model,
                })
            );
            return true;
        }
    };
};

export const updateCompanyInformationAsync = (payload: Client) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiUpdateCompanyInformation({ ...payload });
        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            dispatch(
                setPortalState({
                    clientModel: model,
                })
            );
            message.success("Company information updated successfully");
            return true;
        }
    };
};

export const updateBillingInfoActionAsync = (payload: any) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiUpdateBillingInfoAction({ ...payload });

        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            dispatch(
                setPortalState({
                    billingModel: model,
                })
            );
            message.success("Billing information updated successfully");
            return true;
        }
    };
};
export const updateEmailNotificationsAsync = (payload: Client) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiUpdateEmailNotifications({ ...payload });
        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            message.success("Email notifications updated successfully");
            return true;
        }
    };
};
export const getCountriesAsync = (callback?: (model: Country[]) => void) => {
    return async (dispatch: Dispatch, getState: () => PortalState) => {

        const isCountriesLoading = getState().isCountriesLoading;
        if (getState().countries.length || isCountriesLoading) {
            return;
        }

        dispatch(setPortalState({ isCountriesLoading: true }));
        const countries = await apiGetCountries();
        dispatch(
            setPortalState({
                countries,
                isCountriesLoading: false
            })
        );


    };
};

export const downloadLicensesAsync = (version: string) => {
    return async (dispatch: Dispatch) => {
        const { model, error } = await apiDownloadLiicense(version);
        if (error) {
            message.error(error);
            return false;
        }
        if (model) {
            const url = window.URL.createObjectURL(
                new Blob([model]),
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                "EasyLicense.config",
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
            return true;
        }
    };
};
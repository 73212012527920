import { FC, useEffect } from 'react';
import Billing from '../pages/billing';
import CompanyProfile from '../pages/companyProfile';
import EmailNotifications from '../pages/emailNotifiations';
import Subscriptions from '../pages/subscriptions';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { LoginForm } from '../pages/layout/login/loginPage';
import BoxLayout from '../pages/layout/login';
import MainLayout from '../pages/layout';
import { useAppState } from '../stores';
import { ResetPasswordForm } from '../pages/layout/login/resetPasswordPage';
import { WrappedRoute } from './WrappedRoute';
import Exception404 from '../pages/Exception/404';

export const LoginPath = "/portal/login";
export const DefaultRedirectUrl = "/portal";

const RenderRouter: FC = () => {

    return (
        <AuthWrapper>
            <PrivateRoutes />
        </AuthWrapper>
    );
};

export default RenderRouter;

const PrivateRoutes = () => {
    return (
        <Switch>
            <Route path='/' exact={true}>
                <Redirect to="/portal"></Redirect >
            </Route>
            <Route path='/portal/index' exact={true}>
                <Redirect to="/portal"></Redirect >
            </Route>
            <WrappedRoute path='/portal/companyProfile' title="Company Profile" exact>
                <MainLayout >
                    <CompanyProfile />
                </MainLayout>
            </WrappedRoute>
            <WrappedRoute path='/portal/billing' title="Billing" exact>
                <MainLayout >
                    <Billing />
                </MainLayout>
            </WrappedRoute>
            <WrappedRoute path='/portal/manageSubscriptions' title="Email Notifications" exact>
                <MainLayout >
                    <EmailNotifications />
                </MainLayout>
            </WrappedRoute>
            <WrappedRoute path='/portal' title="Subscriptions" exact >
                <MainLayout >
                    <Subscriptions />
                </MainLayout>
            </WrappedRoute>
            <WrappedRoute title="Page cannot be found" >
                <BoxLayout>
                    <Exception404 />
                </BoxLayout>
            </WrappedRoute>
        </Switch>
    );
}

const AuthWrapper = ({ children }) => {
    return (
        <Switch>
            <WrappedRoute path='/portal/login' title="Login" >
                <BoxLayout>
                    <LoginForm />
                </BoxLayout>
            </WrappedRoute>
            <WrappedRoute path='/portal/reset-password' title="Reset Password" >
                <BoxLayout>
                    <ResetPasswordForm />
                </BoxLayout>
            </WrappedRoute>
            <Route><EnsureLoggedIn>{children}</EnsureLoggedIn></Route>
        </Switch>
    )
}

const EnsureLoggedIn = ({ children }) => {
    const currentUser = useAppState(state => state.currentUser);
    const history = useHistory();
    useEffect(() => {
        if (currentUser === undefined) {
            history.push("/portal/login", {
                returnUrl: history.location.pathname,
            });
        }
    }, [currentUser, history]);

    if (!currentUser) {
        return null;
    }

    return <>{children}</>
};

import { Skeleton, Descriptions, Button, Tag, Tooltip } from "antd";
import { useAppState } from "../../stores";
import * as enums from "../../constants/enums";
import { isInhouseClient } from "../../utils";
import { useAppDispatch } from "../../stores";
import { downloadLicensesAsync } from "../../stores/portalReducer";
import { DownloadOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { InfoCircleOutlined } from '@ant-design/icons';

const useSummarySubsriptionsData = () => {
    const model = useAppState(state => state.clientModel);
    const brandValue = model?.IsBirdview || false ? 1 : 0;
    const brand = enums.getBrand(brandValue);
    const edition = enums.getEdition(model?.Edition || 0);
    const clientLicenseType = model?.ClientLicenseType;
    const activeFullUsers = model?.ActiveFullUsers || 0;
    const activeRestrictedUsers = model?.ActiveResctrictedUsers || 0;
    const extraStorage = model?.ExtraStorage || 0;
    const nonExpiredLicensesUsers = model?.NonExpiredLicensesUsers || 0;
    const activeLicensesByType = model?.ActiveLicensesByType || [];
    const isCancelled = model?.IsCancelled;
    return {
        brand,
        edition,
        clientLicenseType,
        activeFullUsers,
        activeRestrictedUsers,
        extraStorage,
        nonExpiredLicensesUsers,
        activeLicensesByType,
        isCancelled
    };
};

type Props = {
    loading: boolean;
};

export const Summary: React.FC<Props> = ({ loading }) => {
    const { brand, edition, clientLicenseType, activeFullUsers, activeRestrictedUsers, extraStorage, nonExpiredLicensesUsers, activeLicensesByType, isCancelled } = useSummarySubsriptionsData();
    const dispatch = useAppDispatch();
    const [downloading, setDownLoading] = useState(false);
    const downloadLicense = async (version: string) => {
        setDownLoading(true);
        await dispatch(downloadLicensesAsync(version));
        setDownLoading(false);
    }

    const totalUsersForInhousePerpetual = () => {
        return (
            <>
                {
                    Object.entries(activeLicensesByType)
                        .map(([key, value]) =>
                            <Descriptions.Item label={<strong>Total licenses of version {key}</strong>} key={key}>
                                <span>{value}  <Button loading={downloading} style={{ marginLeft: 50 }} type="link" size={"small"} icon={<DownloadOutlined />} onClick={() => downloadLicense(key)}>Download license</Button></span>
                            </Descriptions.Item>
                        )
                }
            </>
        )
    }
    const totalUsersForSubscriptions = () => {
        return (
            <>
                <Descriptions.Item label={<strong>Total Active Licenses</strong>} >
                    {activeFullUsers === nonExpiredLicensesUsers ? activeFullUsers : `Valid ${nonExpiredLicensesUsers} of ${activeFullUsers}`}
                </Descriptions.Item>
                {activeRestrictedUsers > 0 && <Descriptions.Item label={<strong>Restricted Licenses</strong>} >
                    {activeRestrictedUsers}    <span style={{ marginLeft: 12 }}><Tooltip placement="top" color={"blue"}
                        title="Include additional Timekeepers, Contributor and other discounted licenses.">
                        <InfoCircleOutlined />
                    </Tooltip></span>
                </Descriptions.Item>}
            </>
        );
    }

    const subscriptionStatus = () => {
        return (
            <Descriptions.Item label={<strong>Subscription Status</strong>} >
                <Tag color={isCancelled ? "#f5222d" : "#87d068"}>{isCancelled ? "Cancelled" : "Active"}</Tag>
            </Descriptions.Item>);
    }

    return (
        <>
            <Skeleton loading={loading}>
                <Descriptions column={1}>
                    {!isInhouseClient(clientLicenseType) && <>{subscriptionStatus()}</>}
                    {!isCancelled && <>
                        <Descriptions.Item label={<strong>Product</strong>}>{enums.getProductName(brand, edition)}</Descriptions.Item>
                        <Descriptions.Item label={<strong>License type</strong>}>
                            {enums.getEnumName(clientLicenseType, enums.LicenseType)}
                        </Descriptions.Item>

                        <>{isInhouseClient(clientLicenseType) ? totalUsersForInhousePerpetual() : totalUsersForSubscriptions()}</>

                        {extraStorage > 0 && (
                            <Descriptions.Item label={<strong>Extra storage</strong>}>
                                {`${extraStorage} Gb`}
                            </Descriptions.Item>)}
                    </>}
                </Descriptions>
            </Skeleton>
        </>
    );
}
import React from 'react';
import { Select } from 'antd';
import { useCountriesData } from '../../../hooks/useCountriesData';

const Option = Select.Option;

type CountryDropdownProps = {
    value?: string,
    name?: string,
    id?: string,
    className?: string,
    showDefaultOption?: boolean,
    defaultOptionLabel?: string,
    priorityOptions: string[],
    onChange?: (value: string) => void;
    onBlur?: (e: React.FocusEventHandler<HTMLElement>) => void;
    labelType?: string;//'full' | 'short',
    valueType?: string;//'full' | 'short',
    whitelist?: string[],
    blacklist?: string[],
    disabled?: boolean,
    placeholder?: string,
} & Readonly<{
    children?: React.ReactNode;
}>


const defaultProps: Partial<CountryDropdownProps> = {
    value: '',
    name: 'rcrs-country',
    id: '',
    className: '',
    showDefaultOption: true,
    defaultOptionLabel: 'Select Country',
    priorityOptions: [],
    onChange: () => { },
    onBlur: () => { },
    labelType: 'full',
    valueType: 'short',
    whitelist: [],
    blacklist: [],
    disabled: false,
};

export const CountryDropdown: React.FC<CountryDropdownProps> = (props) => {

    const { countries, loading } = useCountriesData(props.priorityOptions, props.whitelist || [], props.blacklist || []);

    const getCountries = () => {
        const { valueType, labelType } = props;
        return countries.map(country => (
            <Option value={(valueType === 'short') ? country.countryShortCode : country.countryName} key={country.countryShortCode}>
                {(labelType === 'short') ? country.countryShortCode : country.countryName}
            </Option>
        ));
    }

    const getDefaultOption = () => {
        if (!props.showDefaultOption) {
            return null;
        }
        return (
            <Option key="default" value="" >{props.defaultOptionLabel}</Option>
        );
    }

    const { name, id, className, value, placeholder, onChange, onBlur, disabled, showDefaultOption, defaultOptionLabel,
        labelType, valueType, whitelist, blacklist, priorityOptions, ...arbitraryProps } = props;

    const filterOption = (input: string, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    const attrs: any = {
        ...arbitraryProps,
        name,
        value: value || "",
        placeholder,
        onChange: (e) => onChange?.(e),
        onBlur: (e) => onBlur?.(e),
        disabled,
        loading
    };
    if (id) {
        attrs.id = id;
    }
    if (className) {
        attrs.className = className;
    }

    return (
        <Select {...attrs} showSearch filterOption={filterOption}>
            {getDefaultOption()}
            {getCountries()}
        </Select>
    );

};

CountryDropdown.defaultProps = defaultProps;



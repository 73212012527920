import { FC, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { ResetPasswordParams, } from '../../../types';
import { resetPasswordAsync } from '../../../stores/portalReducer';
import { useAppDispatch } from '../../../stores';
import { UserOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { Logos } from './logos';

export const ResetPasswordForm: FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [reseting, setReseting] = useState(false);

    const onFinished = async (form: ResetPasswordParams) => {
        setReseting(true);
        dispatch(await resetPasswordAsync(form.email))
            .then((res) => {
                setReseting(false);
                if (!!res) {
                    history.push("/portal/login");
                }
            });
    };

    return (
        <div className={`${styles.box} ${styles.login_page}`}>
            <Row gutter={16} className={`${styles.flex} ${styles.justify_center} ${styles.logo}`} >
                <Col span={4} className={`${styles.flex} ${styles.justify_center}`} >
                    <Logos />
                </Col>
            </Row>
            <Row gutter={16} className={`${styles.flex} ${styles.justify_center}`} >
                <h2 >Reset your password</h2>
            </Row>
            <Row gutter={16} className={styles.form}>
                <Col span={24} >
                    <p>Please enter your Customer Portal e-mail address to receive password reset instructions.</p>
                    <Form<ResetPasswordParams> form={form} onFinish={onFinished} className={styles.login_page_form} >
                        <Form.Item name="email" rules={[{
                            type: 'email',
                            message: 'The input is not valid e-mail!',
                        },
                        {
                            required: true,
                            whitespace: true,
                            message: 'Please input your e-mail!'
                        },
                        ]}>
                            <Input size="large" prefix={<UserOutlined className={styles.site_form_item_icon} />} placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item >
                            <Button size="large" type="primary" htmlType="submit" className={styles.login_form_button} loading={reseting}>
                                Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row gutter={16} className="back-link" >
                <Col span={20} >
                    <Link to="/portal/login">Back to login page</Link>
                </Col>
            </Row>
        </div >
    );
};




export const editions = [
    { value: -1, name: "Unknown" },
    { value: 0, name: "Legacy Team" },
    { value: 1, name: "Legacy Enterprise" },
    { value: 2, name: "Lite" },
    { value: 3, name: "Team" },
    { value: 4, name: "Enterprise" },
    { value: 7, name: "PSA Lite" },
    { value: 5, name: "PSA Team" },
    { value: 6, name: "PSA Enterprise" },

    { value: 8, name: "Project Management Lite" },
    { value: 9, name: "Project Management Team" },
    { value: 10, name: "Project Management Enterprise" },

    { value: 11, name: "Resource Management Team" },
    { value: 12, name: "Resource Management Enterprise" },
];

export const trialTypes = [
    { value: 0, name: "Self Serve" },
    { value: 1, name: "Sales Assisted" },
];

export const brands = [
    { value: 0, name: "Easy Projects" },
    { value: 1, name: "Birdview" },
];

export const useInStats = [
    { value: 0, name: "Yes" },
    { value: 1, name: "No" },
];

export const roles = [
    { value: 1, name: "Administrator" },
    { value: 2, name: "Accounting" },
    { value: 3, name: "Sales" },
    { value: 4, name: "Support" },
    { value: 6, name: "Affilate" },
    { value: 7, name: "Statistics" },
];

export const userStatuses = [
    { value: 1, name: "Active" },
    { value: 0, name: "Deactivated" },
];
export const salesStatuses = [
    { value: 1, name: "Active" },
    { value: 0, name: "Inactive" },
];
export const showInDashboards = [
    { value: 1, name: "Yes" },
    { value: 0, name: "No" },
];
export const categories = [
    { value: 1, name: "Retention" },
    { value: 0, name: "Inbound" },
];
export const getBrand = (value) => {
    let brand = brands.find(x => x.value === value);
    if (!!brand) {
        return brand.name;
    }
    return value;
};
export const getEdition = (value) => {
    let edition = editions.find(x => x.value === value);
    if (!!edition) {
        return edition.name;
    }
    return value;
};
export const getClientType = (item) => {
    return item.isHosted ? "Hosted" : "In-house";
};
export const getClientStatus = (item) => {
    return item.isCanceled ? "Cancelled" : "Active";
};
export const getEnumName = (value, enums) => {
    return (enums.find(x => x.value === value) || []).name || value;
};

export const invoiceTerms = [
    { value: 0, name: "Term0" },
    { value: 30, name: "Term30" },
    { value: 45, name: "Term45" },
    { value: 60, name: "Term60" },
];

export const taxes = [
    { value: 0, name: "No Tax" },
    { value: 5, name: "5% Tax" },
    //   { value: 12, name: "12% Tax" },
    { value: 13, name: "13% Tax" },
    //   { value: 14, name: "14% Tax" },
    { value: 15, name: "15% Tax" },
];

export const products = [
    {
        value: 0,
        groupName: "Hosted",
        items: [
            { value: "EP-TEAM-Host", name: "Easy Projects Legacy TEAM Host Subscription" },
            { value: "EP-ENT-Host", name: "Easy Projects Legacy ENT Host Subscription" },
            { value: "EP-NPS-TEAM-Host", name: "Easy Projects TEAM Host Subscription" },
            { value: "EP-NPS-ENT-Host", name: "Easy Projects ENT Host Subscription" },
            { value: "PS-LITE-Host", name: "Birdview PSA LITE Host Subscription" },

            { value: "PS-TEAM-Host", name: "Birdview PSA TEAM Host Subscription" },
            { value: "PS-ENT-Host", name: "Birdview PSA ENT Host Subscription" },
            
            { value: "PM-LITE-Host", name: "Birdview Project Management LITE Host Subscription" },
            { value: "PM-TEAM-Host", name: "Birdview Project Management TEAM Host Subscription" },
            { value: "PM-ENT-Host", name: "Birdview Project Management ENT Host Subscription" },
      
            { value: "RM-TEAM-Host", name: "Birdview Resource Management TEAM Host Subscription" },
            { value: "RM-ENT-Host", name: "Birdview Resource Management ENT Host Subscription" },
        ]
    },
    {
        value: 1,
        groupName: "In-house",
        items: [
            { value: "EP-TEAM", name: "EP.NET TEAM In-House Subscription" },
            { value: "EP-ENT", name: "EP.NET ENT In-House Subscription" },
        ]
    },
    {
        value: 2,
        groupName: "3rd party",
        items: [
            { value: "Private-Cloud", name: "Private Cloud Subscription " },
            { value: "PBI", name: "Power BI Subscription" },
            { value: "outlookaddin", name: "MS Outlook Add-in for Easy Projects" },
            { value: "qbconnector", name: "Easy Projects To QuickBooks Connector" },
            { value: "EPO365", name: "Outlook 365 for Easy Projects" },
            { value: "EPCC", name: "Easy Projects for Adobe Creative Cloud" },
        ]
    },
    {
        value: 3,
        groupName: "Services",
        items: [
            { value: "EP-TRAINING", name: "Training" },
            { value: "EP-SERVICES", name: "Easy Projects Services" },
            { value: "PROF-SERVICES", name: "Professional Services" }
        ]
    }
];

export const taxRateStatuses = [
    { value: 1, name: "Active" },
    { value: 0, name: "Archived" },
];

export const LicenseRenewalType = [
    { value: 0, name: "Automatic" },
    { value: 1, name: "Manual" },
    { value: 2, name: "GS Auto renewal off" },
    { value: 3, name: "Free" },
];

export const LicenseType = [
    { value: 0, name: "Unknown" },
    { value: 1, name: "In-house Perpetual" },
    { value: 2, name: "In-house Perpetual with GS" },
    { value: 3, name: "Limited access" },
    { value: 4, name: "Hosted subscription" },
    { value: 5, name: "Source" },
    { value: 6, name: "Source with GS" },
    { value: 7, name: "Extra Storage" },
    { value: 8, name: "Additional Services" },
    { value: 9, name: "In-house subscription" },
    { value: 10, name: "Add Ins" },
    { value: 11, name: "Private Cloud" },
    { value: 12, name: "Power BI" },
    { value: 13, name: "PS Maintenance" },
];

export const getProductName = (brand: string, edition: string) => `${brand} ${edition}`;


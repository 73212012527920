import Layout from "antd/lib/layout/layout";
import { Fragment, ReactNode, Suspense } from "react";
import { PageLoading } from "../../components/PageLoading";
import styles from './style.module.scss';

interface IBoxLayoutProps {
    children?: ReactNode;
}

const BoxLayout: React.FC<IBoxLayoutProps> = ({ children }) => {
    return (
        <Fragment>
            <Layout>
                <div className={styles.box_row}>
                    <div className={styles.box_center}  >
                        {children}
                    </div>
                </div>
            </Layout>
            <Suspense fallback={<PageLoading />}></Suspense>
        </Fragment>
    );
}
export default BoxLayout;
import React from 'react';
import { Row, Col } from 'antd';
import { Logos } from '../layout/login/logos';
import styles from '../layout/login/style.module.scss';

export default class Exception404 extends React.Component {

    render() {
        return (
            <div className={`${styles.box} ${styles.login_page}`}>
                <Row gutter={16} className={`${styles.flex} ${styles.justify_center} ${styles.logo}`} >
                    <Col span={4} className={`${styles.flex} ${styles.justify_center}`} >
                        <Logos />
                    </Col>
                </Row>
                <Row gutter={16} className="form">
                    <Col span={24} >
                        <h1 style={{ textAlign: "center", paddingBottom: 20 }}>404. Sorry... Page cannot be found.</h1>
                    </Col>
                </Row>
                <Row gutter={16} className="form">
                    <Col span={24} >
                        <p>There might be several reasons why you see this page:</p>
                        <ul>
                            <li>If you typed the page address in the Address bar, make sure that it is spelled correctly.</li>
                            <li>Open the <a href="/portal">{window.location.origin}/portal</a> home page and look for links to the information you want.</li>
                        </ul>
                    </Col>
                </Row>
                <Row gutter={16} className="form">
                    <Col span={24} >
                        <p>Feel free to reach out to us at <a href="mailto:info@birdviewpsa.com">info@birdviewpsa.com</a></p>
                    </Col>
                </Row>
            </div>

        );
    }
}
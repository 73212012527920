
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './stores';
import RenderRouter from './router';

function App() {
  return (

    <Provider store={store}>
      <BrowserRouter>
        <RenderRouter />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
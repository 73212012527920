
// const codeMessage = {
//     200: 'The server successfully returned the requested data.',
//     201: 'New or modified data is successful.',
//     202: 'A request has entered the background queue (asynchronous task).',
//     204: 'Delete data successfully.',
//     302: 'User is not autorized.',
//     400: 'The request was sent with an error. The server did not perform any operations to create or modify data.',
//     401: 'The user does not have permission (token, username, password is incorrect).',
//     403: 'User is authorized, but access is forbidden.',
//     404: 'The request sent is for a record that does not exist and the server is not operating.',
//     406: 'The format of the request is not available.',
//     410: 'The requested resource is permanently deleted and will not be obtained again.',
//     422: 'When creating an object, a validation error occurred.',
//     500: 'The server has an error. Please check the server.',
//     502: 'Gateway error.',
//     503: 'The service is unavailable, the server is temporarily overloaded or maintained.',
//     504: 'The gateway timed out.',
// };

// const checkStatus = (response: { status: string | number; statusText: any; url: any; }) => {

//     if (response.status >= 200 && response.status < 300) {
//         return response;
//     }
//     const errortext = response.statusText;
//     notification.error({
//         message: `Request error ${response.status}: ${response.url}`,
//         description: errortext,
//     });
//     const error = new Error(errortext);
//     error.name = response.status as string;
//     //error.response = response;
//     throw error;
// };

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export type Response<T = any> = {
    status: boolean;
    message: string;
    result: T;
};

export type MyResponse<T = any> = Promise<Response<T>>;

export const request = (
    url: string,
    option?: any,
): any => {
    const options = {
        ...option,
    };

    const defaultOptions = {
        credentials: 'include',
    };
    const newOptions = { ...defaultOptions, ...options };
    if (
        newOptions.method === 'POST' ||
        newOptions.method === 'PUT' ||
        newOptions.method === 'DELETE'
    ) {
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                ...newOptions.headers,
            };
            newOptions.body = JSON.stringify(newOptions.body);
        } else {
            // newOptions.body is FormData
            newOptions.headers = {
                Accept: 'application/json',
                ...newOptions.headers,
            };
        }
    }

    return fetch(url, newOptions);
}


// IE/FF/Chrome handle ISO date format in Date.parse inconsistently so we need to have our own implementation
var isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d*))?Z?$/;
const fromISOString = function (date: string) {
    var parts = isoDateRegex.exec(date);
    if (parts) {
        var ms = +((parts[7] || "") + "000").substring(0, 3); // handling 1/10 and 1/100 and trimming extra digits (may come from C# DateTime)

        return new Date(Date.UTC(+parts[1], +parts[2] - 1, +parts[3], +parts[4], +parts[5], +parts[6], ms)); // always UTC
    }

    return null;
};

const isoDateReviver = (key: any, value: string) => {
    if (typeof value === 'string') {
        var date = fromISOString(value);
        if (date) {
            // treating parsed date as local client date
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
        }
    }
    return value;
}

export const handleResponse = (response: any): any => {
    return new Promise<void>(async (resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                const text = await response.text();
                const json = text ? JSON.parse(text, isoDateReviver) : null;
                resolve(json);
            }
            else if (contentType && contentType.includes("text/plain")) {
                const blob = response.blob();
                resolve(blob);
            }
            else {
                resolve();
            }
        } else {

            // return error message from response body
            response.text().then(text => {
                return reject(text.slice(1, -1));
            });
        }
    });
}

export const handleError = (error: any): any => {
    return Promise.reject(error && error.message);
}

import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { useAppState } from '../stores';
import { getPageTitle } from '../utils';


export type WrappedRouteProps = {
    title?: string;
} & RouteProps;



export function WrappedRoute({ title, ...routeProps }: WrappedRouteProps) {
    const { currentUser } = useAppState(state => state);

    useEffect(() => {
        document.title = getPageTitle(title, currentUser?.IsBirdviewBrand);
        if (currentUser?.IsBirdviewBrand) {
            const favicon = document.getElementById("favicon");
            if (favicon)
                (favicon as HTMLLinkElement).href = '/client/bv-favicon.ico';
        }

    }, [currentUser, title]);


    return <Route {...routeProps} />;
};

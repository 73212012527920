import React, { Component, FC, useState } from 'react';
import { Button, Col, Form, Input, Popover, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { ChangePasswordParams } from '../../types';
import { useAppDispatch } from '../../stores';
import { changePasswordAsync } from '../../stores/portalReducer';


type Props = {
    onClose: () => void;
}

export const ChangePasswordForm: FC<Props> = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [reseting, setReseting] = useState(false);

    const onFinished = async (values: ChangePasswordParams) => {
        setReseting(true);
        dispatch(await changePasswordAsync(values))
            .then((res) => {
                setReseting(false);
                if (typeof res === 'string') {
                    const errorData = res.split(':');
                    form.setFields([
                        {
                            name: errorData[0],
                            errors: [errorData[1]],
                        },
                    ]);
                } else {
                    form.resetFields();
                    onClose();
                }
            });
    };

    return (
        <Row gutter={16}>
            <Col span={24} >
                <Form<ChangePasswordParams> layout="vertical" form={form} onFinish={onFinished} >
                    <Form.Item label="Old password" name="oldPassword" rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: 'Please input your old password!'
                        },
                    ]}>
                        <Input.Password prefix={<LockOutlined />} placeholder="Old Password" />
                    </Form.Item>
                    <Form.Item label="New password" name="newPassword" rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: 'Please input your new password!'
                        },
                    ]}>
                        <Input.Password prefix={<LockOutlined />} placeholder="New Password" />
                    </Form.Item>
                    <Form.Item >
                        <Row>
                            <Col span={12}><Button key="change" type="primary" htmlType="submit" loading={reseting}>Change</Button></Col>
                            <Col span={12}><Button key="cancel" onClick={onClose}>Cancel</Button></Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}


export const ChangePasswordPopup: React.FC = () => {
    const [visible, setVisible] = React.useState(false);
    const wrapper = React.createRef();
    const handleCloseDetails = () => {
        setVisible(false);
    };

    const handleVisibleChange = (visible) => {
        setVisible(visible);
    }

    return (
        <Popover placement="bottomLeft"
            title={<div>Change password</div>}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            content={<div ><ChangePasswordForm onClose={handleCloseDetails} /></div>}
            trigger="click">
            <Button key="changePWD" type="link">Change Password</Button>
        </Popover>
    );
}

import React from "react";

declare global {
    interface Window {
        ac_env: string;
    }
}

export const Intercom: React.FC = () => {

    React.useEffect(() => {
        // @ts-ignore
        window.Intercom('boot', {
            app_id: 'kzmrgubj',
        });
        // @ts-ignore
        return () => window.Intercom('shutdown')
    }, []);

    return null;
};




import { Button, PageHeader } from 'antd';
import * as React from 'react';
import { ChangePasswordPopup } from './changePassword';
import { ClientProfile } from './clientProfile';

class CompanyProfile extends React.Component {


    public render() {

        return (
            <>
                <PageHeader title="Company profile"
                    extra={[
                        <ChangePasswordPopup key="change-pwd-popup" />
                    ]}
                />
                <div style={{ padding: '0 24px 24px', minHeight: 20 }}>
                    <ClientProfile />
                </div>

            </>
        )
    }
}
export default CompanyProfile;
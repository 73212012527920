import React from "react";
import { Country } from "../pages/components/CountryStates";
import { useAppDispatch, useAppState } from "../stores";
import { getCountriesAsync } from "../stores/portalReducer";


export const useCountriesData = (priorityCountries: string[] = [], whitelist: string[] = [], blacklist: string[] = []) => {
    const dispatch = useAppDispatch();

    const allCountires = useAppState(state => state.countries);
    const loading = !allCountires.length;
    React.useEffect(() => {
        dispatch(getCountriesAsync());
    }, [dispatch]);

    const countries = React.useMemo(() => filterCountries(allCountires, priorityCountries, whitelist, blacklist),
        [allCountires, priorityCountries, whitelist, blacklist]);


    return {
        countries,
        loading
    };
}

const filterCountries = (countries: Country[], priorityCountries: string[] = [], whitelist: string[] = [], blacklist: string[] = []) => {
    let countriesListedFirst = [] as Country[];
    let filteredCountries = Object.entries(countries).map(([, country]) => country);

    if (whitelist.length > 0) {
        filteredCountries = countries.filter(x => whitelist.indexOf(x.countryShortCode) > -1);
    } else if (blacklist.length > 0) {
        filteredCountries = countries.filter(x => blacklist.indexOf(x.countryShortCode) === -1);
    }

    if (priorityCountries.length > 0) {

        // ensure the countries are added in the order in which they are specified by the user
        priorityCountries.forEach((slug) => {
            const result = filteredCountries.find(x => x.countryShortCode === slug);
            if (result) {
                countriesListedFirst.push(result);
            }
        });

        filteredCountries = filteredCountries.filter(x => priorityCountries.indexOf(x.countryShortCode) === -1);
    }

    return countriesListedFirst.length ? [...countriesListedFirst, ...filteredCountries] : filteredCountries;
};
import React, { useEffect } from 'react';
import { Select } from 'antd';
import { Region } from '.';
import { useIsUnmounted } from '../../../hooks';
import { useCountriesData } from '../../../hooks/useCountriesData';

const Option = Select.Option;

type StateDropdownProps = {

    country: string,
    countryValueType: string,

    value?: string,
    name?: string,
    id?: string,
    classes?: string,
    blankOptionLabel?: string,
    showDefaultOption?: boolean,
    defaultOptionLabel?: string,
    priorityOptions?: string[],
    onChange?: (value: string) => void;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    labelType?: string,
    valueType?: string,
    disabled?: boolean,
    disableWhenEmpty?: boolean,
    placeholder?: string,
    customOptions?: string[],
} & Readonly<{
    children?: React.ReactNode;
}>

const defaultProps: StateDropdownProps = {
    country: '',
    value: '',
    name: 'rcrs-region',
    id: '',
    classes: '',
    blankOptionLabel: 'N/A',
    showDefaultOption: true,
    defaultOptionLabel: 'Select region',
    onChange: (value: string) => { },
    onBlur: () => { },
    countryValueType: 'short',
    labelType: 'full',
    valueType: 'short',
    disabled: false,
    disableWhenEmpty: true,
    customOptions: []
};

export const StateDropdown: React.FC<StateDropdownProps> = (props) => {
    const { countries, loading } = useCountriesData();
    const { value, country, onChange, onBlur, id, name, classes, disabled, blankOptionLabel, showDefaultOption,
        defaultOptionLabel, labelType, valueType, countryValueType, disableWhenEmpty, customOptions,
        ...arbitraryProps } = props;

    const [regions, setRegions] = React.useState([] as Region[]);
    const { isUnmountedRef } = useIsUnmounted();

    const getCustomOptions = (regions: Region[]) => {
        const { customOptions } = props;

        const duplicateRegions = getDuplicates(regions);

        if (duplicateRegions.length) {
            console.error('Error: Duplicate regions present: ' + duplicateRegions.toString() + '.\nThe above item(s) is/are already getting added to the region dropdown by the library.');
            return [];
        }

        return (customOptions || []).map((option) => {
            if (option) {
                return { name: option, shortCode: option };
            }
            else {
                return {};
            }
        });
    }

    const getDuplicates = (regions: Region[]): Region[] => {
        const { customOptions, valueType } = props;
        const regionKey: string = valueType === 'full' ? 'regionName' : 'regionShortCode';

        return regions.filter((region) => (customOptions || []).indexOf(region[regionKey]) !== -1).map(region => region[regionKey]) as Region[];
    }

    const getRegions = (country: string): Region[] => {
        if (!country) {
            return [];
        }
        const { countryValueType } = props;
        let regions = [] as any;
        countries.filter((c) => {
            let field = (countryValueType === 'full') ? c.countryName : c.countryShortCode;
            if (field === country) {
                regions = c.regions;
            }
            return regions;
        });

        if (!regions || regions.length === 0) {
            return [];
        }
        return Object.entries(regions).map(([, region]) => {
            let { name, shortCode } = region as Region;

            return { name: name, shortCode: shortCode } as Region;
        });
    }

    const getRegionList = () => {
        const { labelType, valueType } = props;
        return regions.map(({ name, shortCode }) => {
            const label = (labelType === 'full') ? name : shortCode;
            const value = (valueType === 'full') ? name : shortCode;
            return <Option value={value} key={name}>{label}</Option>;
        });
    }

    // there are two default options. The "blank" option which shows up when the user hasn't selected a country yet, and
    // a "default" option which shows
    const getDefaultOption = () => {
        const { blankOptionLabel, showDefaultOption, defaultOptionLabel, country } = props;
        if (!country || regions.length === 0) {
            return <Option value="">{blankOptionLabel}</Option>;
        }
        if (showDefaultOption) {
            return <Option value="">{defaultOptionLabel}</Option>;
        }
        return null;
    }

    useEffect(() => {
        if (isUnmountedRef.current) {
            return;
        }

        if (country) {
            const defaultRegions = getRegions(country);
            setRegions([
                ...defaultRegions,
                ...getCustomOptions(defaultRegions)
            ] as Region[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, isUnmountedRef, loading]);


    const isDisabled = disabled || (disableWhenEmpty && country === '') || (regions.length === 0 && !loading);
    const attrs: any = {
        ...arbitraryProps,
        name,
        value: value || "",
        onChange: (e) => onChange?.(e),
        onBlur: (e) => onBlur?.(e),
        disabled: isDisabled
    };
    if (id) {
        attrs.id = id;
    }
    if (classes) {
        attrs.className = classes;
    }

    return (
        <Select {...attrs} showSearch>
            {getDefaultOption()}
            {getRegionList()}
        </Select>
    );
}

StateDropdown.defaultProps = defaultProps;

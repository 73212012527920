import { Divider, Table, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import * as enums from "../../constants/enums";
import { Subscription } from "../../types";
import { hasRenewOptions, isExtraStorage, isHostedClient, isInhouseClient } from "../../utils";

const { Text } = Typography;

type Props = {
    loading: boolean;
    rowKey: string;
    items: Subscription[];
    clientLicenseType?: number;
}

export const ActiveSubscriptions: React.FC<Props> = ({ loading, items, rowKey, clientLicenseType }) => {
    const columns: ColumnsType<Subscription> = [
        {
            title: "Created on",
            dataIndex: 'FirstOrderDate',
            key: 'FirstOrderDate',
            width: 150,
            render: val => <>{moment(val).format('MM/DD/YYYY')}</>,
        },

        {
            title: <>{isHostedClient(clientLicenseType) ? "Users / Storage" : "Users"}</>,
            dataIndex: 'NumberOfUsers',
            key: 'NumberOfUsers',
            align: "right",
            width: 150,
            render: (text, record) => {
                if (isExtraStorage(record.LicenseType)) {
                    return <> {record.ExtraStorage} Gb</>
                } else {
                    return <>
                        {record.IsRestrictedLicense &&
                            <Tooltip placement="top" color={"blue"}
                                title="Include additional Timekeepers, Contributor and other discounted licenses.">
                                <Tag color={"purple"}>RESTRICTED</Tag>
                            </Tooltip>}
                        {record.NumberOfUsers}
                    </>
                }
            },
        },
        {
            title: "Product",
            dataIndex: 'ProductName',
            key: 'ProductName',
            width: 300,
            render: (text, record) => {
                const version = isHostedClient(record.LicenseType) || isExtraStorage(record.LicenseType) ? <Tag >latest</Tag> :
                    <Tag >{record.Version}</Tag>
                return (
                    <> {record.ProductName} {enums.getEdition(record.Edition)}: {version}</>
                );
            },
        },
        {
            title: "Type",
            dataIndex: 'LicenseType',
            key: 'LicenseType',
            width: '20%',
            render: (text, record) => {
                return (
                    <>  {enums.getEnumName(record.LicenseType, enums.LicenseType)} {record.LicenseRenewal === 3 && <Text style={{ color: "blue" }}>(FREE)</Text>}</>
                );
            },
        },
        {
            title: "Last Payment",
            dataIndex: 'LastPaymentDate',
            key: 'LastPaymentDate',
            width: 150,
            render: (text, record) => {
                return (
                    <>  {record.LicenseRenewal !== 3 && <>{record.LastPaymentDate ? moment(record.LastPaymentDate).format('MM/DD/YYYY') : ""}</>} 
                    </>
                );
            },
        },
        {
            title: "Term (months)",
            dataIndex: 'ExpirationTerm',
            key: 'ExpirationTerm',
            align: "right",
            render: (text, record) => {
                if (hasRenewOptions(record.LicenseType) && record.LicenseRenewal !== 3) {
                    return <>{record.ExpirationTerm}</>
                }
                return <></>
            },
        },
        {
            title: "Renews on",
            dataIndex: 'ExpireDate',
            key: 'ExpireDate',
            render: (text, record) => {
                if (hasRenewOptions(record.LicenseType) && record.LicenseRenewal !== 3) {
                    const expire = moment(record.ExpireDate).format('MM/DD/YYYY');
                    const cancelledAt = moment(record.CancelledAt || record.ExpireDate).format('MM/DD/YYYY');
                    const today = moment().format('MM/DD/YYYY');
                    if (record.IsStripeSubscriptionActive) {
                        if (moment(expire) < moment(today)) {
                            return <span style={{ color: "red" }} > {expire}</span >
                        }
                        return <span> {expire}</span >
                    } else {
                        return <span style={{ color: "darkred" }}> Cancelled at {cancelledAt}</span >
                    }
                }
                return <></>
            },
        },
    ];

    return (
        <>
            <Divider orientation="left" >Active {isInhouseClient(clientLicenseType) ? "Licenses" : "Subscriptions"}</Divider>
            <Table<Subscription>
                bordered
                loading={loading}
                dataSource={items}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
            />
        </>);
}
import { PageHeader } from 'antd';
import * as React from 'react';
import { BillingsData } from './billingData';


class Billing extends React.Component {
    public render() {
        return (
            <>
                <PageHeader title="Billing" />
                <div style={{ padding: '0 24px 24px', minHeight: 20 }}>
                    <BillingsData />
                </div>

            </>
        )
    }
}
export default Billing;
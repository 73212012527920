import { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { LoginParams } from '../../../types';
import { getCurrentUserAsync, loginAsync } from '../../../stores/portalReducer';
import { useAppDispatch } from '../../../stores';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { DefaultRedirectUrl } from '../../../router';
import { PageLoading } from '../../components/PageLoading';
import { useIsUnmounted } from '../../../hooks';
import { Logos } from './logos';

const initialValues: LoginParams = {
    email: '',
    password: '',
};

export const LoginForm: FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { isUnmountedRef } = useIsUnmounted();

    const [isAuthChecked, setIsAuthChecked] = useState(false);
    let returnUrl = history.location.state?.returnUrl ?? DefaultRedirectUrl;
    if (returnUrl === "/portal/login") {
        returnUrl = DefaultRedirectUrl;
    }

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinished = async (form: LoginParams) => {
        setLoading(true);
        const res = await dispatch(loginAsync(form))

        setLoading(false);
        if (!!res) {
            history.push(returnUrl);
        }

    };

    useEffect(() => {
        dispatch(getCurrentUserAsync((user) => {
            if (isUnmountedRef.current) {
                return;
            }

            if (user) {
                history.push(returnUrl);
            } else {
                setIsAuthChecked(true);
            }
        }));
    }, [isUnmountedRef, history, dispatch, returnUrl]);

    if (!isAuthChecked) {
        return <PageLoading />
    }

    return (
        <div className={`${styles.box} ${styles.login_page}`}>
            <Row gutter={16} className={`${styles.flex} ${styles.justify_center} ${styles.logo}`} >
                <Col span={4} className={`${styles.flex} ${styles.justify_center}`} >
                    <Logos />
                </Col>
            </Row>
            <Row gutter={16} className={`${styles.flex} ${styles.justify_center}`} >
                <h2 >Customer Portal Login</h2>
            </Row>
            <Row gutter={16} className={styles.form}>
                <Col span={24} >
                    <Form<LoginParams> form={form} onFinish={onFinished} className={styles.login_page_form} initialValues={initialValues}>
                        <Form.Item name="email" className={styles.form_item} rules={[{
                            type: 'email',
                            message: 'The input is not valid e-mail!',
                        },
                        {
                            required: true,
                            whitespace: true,
                            message: 'Please input your e-mail!'
                        },
                        ]}>
                            <Input size="large" prefix={<UserOutlined className={styles.site_form_item_icon} />} placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item name="password" className={styles.form_item} rules={[{ required: true, whitespace: true, message: 'Please input your password!' }]}>
                            <Input size="large" prefix={<LockOutlined className={styles.site_form_item_icon} />} type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item name="rememberMe" className={styles.form_item} valuePropName="checked">
                            <>
                                <Checkbox>Remember me</Checkbox>
                                <Link className={styles.login_form_forgot} to="/portal/reset-password">Forgot password</Link>
                            </>
                        </Form.Item>
                        <Form.Item className={styles.form_item}>
                            <Button size="large" type="primary" htmlType="submit" className={styles.login_form_button}
                                loading={loading}
                            >
                                Sign in
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div >
    );
};




import Meta from 'antd/lib/card/Meta';
import { useAppState } from '../../stores';
import { getSiteNameByBrand } from '../../utils';

export const SiteName = () => {
    const { currentUser } = useAppState(state => state);

    return (
        <>
            <div style={{ width: '100%' }}>
                <Meta title={getSiteNameByBrand(currentUser?.IsBirdviewBrand)}
                    style={{
                        textAlign: "center",
                        lineHeight: '24px',
                    }}
                    description={currentUser?.Company} />
            </div>
        </>
    );
}
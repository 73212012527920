import React from "react";
import { useAppDispatch, useAppState } from "../stores";
import { getBillingModelAsync } from "../stores/portalReducer";


export const useBillingModel = () => {
    const dispatch = useAppDispatch();
    const model = useAppState(state => state.billingModel);
    const loading = !model;

    React.useEffect(() => {
        if (!model) {
            dispatch(getBillingModelAsync());
        }
    }, [model, dispatch]);


    return { model, loading }
}
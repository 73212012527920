import React from "react";
import { useAppDispatch, useAppState } from "../stores";
import { getClientModelAsync } from "../stores/portalReducer";


export const useClientModel = () => {
    const dispatch = useAppDispatch();
    const model = useAppState(state => state.clientModel);
    const loading = !model;

    React.useEffect(() => {
        if (!model) {
            dispatch(getClientModelAsync());
        }
    }, [model, dispatch]);


    return { model, loading }
}
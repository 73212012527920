import * as React from "react";
import { Avatar, Spin } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { useAppState } from "../../stores";

function getColor(username: string) {
    const colors = [
        '#ffa38a',
        '#a9a7e0',
        '#D686D4',
        '#96CE56',
        '#4A90E2',
        '#62b3d0',
        '#ef7676',
    ];
    const firstChar = username.charCodeAt(0);
    const secondChar = username.charCodeAt(1);
    const thirdChar = username.charCodeAt(2);

    return colors[(firstChar + secondChar + thirdChar) % 7];
}

const loading = (
    <span>
        <Spin
            size="small"
            style={{
                marginLeft: 8,
                marginRight: 8,
            }}
        />
    </span>
);

export const GetUsernameAvatar = (size: AvatarSize = 'large') => {
    const { currentUser } = useAppState(state => state);

    if (!currentUser || !currentUser.Name) {
        return loading;
    }
    const username = currentUser?.Name;
    return (
        <div>
            <Avatar
                style={{
                    backgroundColor: getColor(username),
                    verticalAlign: 'middle',
                }}
                size={size}
            >
                {username ? username.charAt(0).toUpperCase() : ''}
            </Avatar>
            <span style={{ marginLeft: 10 }}>{username}</span>
        </div>
    );
};
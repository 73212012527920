import { Divider, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import * as enums from "../../constants/enums";
import { Subscription } from "../../types";
import { isAddIns } from "../../utils";
const { Text } = Typography;

type Props = {
    loading: boolean;
    rowKey: string;
    items: Subscription[];
}

export const CancelledThirdPartySubscriptions: React.FC<Props> = ({ loading, items, rowKey }) => {
    const columns: ColumnsType<Subscription> = [
        {
            title: "Created on",
            dataIndex: 'FirstOrderDate',
            key: 'FirstOrderDate',
            width: 150,
            render: val => <>{moment(val).format('MM/DD/YYYY')}</>,
        },
        {
            title: "Users",
            dataIndex: 'NumberOfUsers',
            key: 'NumberOfUsers',
            align: "right",
            width: 150,
            render: (text, record) => {
                if (isAddIns(record.LicenseType)) {
                    return <> {record.NumberOfUsers}</>
                }
            },
        },
        {
            title: "Product",
            dataIndex: 'ProductName',
            key: 'ProductName',
            width: 300,
            render: (text, record) => {
                return (
                    <> {record.ProductName} {record.LicenseRenewal === 3 && <Text style={{ color: "blue" }}>(FREE)</Text>}</>
                );
            },
        },
        {
            title: "Cancelled on",
            dataIndex: 'CancellationDate',
            key: 'CancellationDate',
            render: val => <>{val ? moment(val).format('MM/DD/YYYY') : ""}</>,
        },
    ];
    return (
        <>
            <Divider orientation="left" >Cancelled 3rd party Subscriptions</Divider>
            <Table<Subscription>
                bordered
                loading={loading}
                dataSource={items}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
            />
        </>);
}
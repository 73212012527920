import React, { useState } from 'react';
import { Form, Divider, Input, Button } from 'antd';
import { useAppDispatch } from '../../stores';
import { updateCompanyInformationAsync } from '../../stores/portalReducer';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Client } from '../../types';
import { PageLoading } from '../components/PageLoading';
import styles from './style.module.scss';
import { ContactForm } from './contactForm';
import { useClientModel } from '../../hooks/useClientModel';
import { AddressForm } from './addressForm';

export const ClientProfile: React.FC = () => {
    const dispatch = useAppDispatch();
    const { model, loading } = useClientModel();

    const [updating, setUpdating] = useState(false);

    const [showBillingContact, setShowBillingContact] = useState(false);
    const [showBillingAddress, setShowBillingAddress] = useState(false);

    const [form] = Form.useForm();

    React.useEffect(() => {
        if (model) {
            setShowBillingContact(!model?.Client.BillingContactSameAsProduct);
            setShowBillingAddress(!model?.Client.BillingAddressSameAsShipping);
        }
    }, [model]);

    const onChangeContact = (e: any) => {
        setShowBillingContact(!e.target.checked);
        
            let primaryContact= form.getFieldValue("PrimaryContact");
            form.setFieldsValue({
                ["BillingContact"]:{
                    FirstName: primaryContact.FirstName,
                    LastName: primaryContact.LastName,
                    Email: primaryContact.Email,
                    Phone: primaryContact.Phone,
                    Title: primaryContact.Title,
                }
            });
        }

    const onChangeAddress = (e: any) => {
        setShowBillingAddress(!e.target.checked);
        let shippingAddress= form.getFieldValue("ShippingAddress");
            form.setFieldsValue({
                ["BillingAddress"]:{
                    AddressCountry: shippingAddress.AddressCountry,
                    AddressState: shippingAddress.AddressState,
                    AddressLine1: shippingAddress.AddressLine1,
                    AddressLine2: shippingAddress.AddressLine2,
                    AddressCity: shippingAddress.AddressCity,
                    AddressZip: shippingAddress.AddressZip,
                }
            });
    }
    const onFinished = async (client: Client) => {
        setUpdating(true);
        dispatch(await updateCompanyInformationAsync({
            ...client,
            BillingContactSameAsProduct: !showBillingContact,
            BillingAddressSameAsShipping: !showBillingAddress,
        }))
            .then((res) => {
                setUpdating(false);
            });
    };

    if (loading) {
        return <PageLoading />
    }

    return (
        <div>
            <Form<Client> form={form} onFinish={onFinished} layout="vertical" initialValues={model?.Client}>
                <div className={styles.container} >
                    <div className={styles.items}>
                        <Form.Item label='Company:' name={"Company"}>
                            <Input placeholder="Company" />
                        </Form.Item>
                    </div>
                    <div className={styles.items}>
                        &nbsp;
                    </div>

                    <div className={styles.items}>
                        <Divider orientation="left" >Primary Contact</Divider>
                        <Form.Item name="PrimaryContact">
                            <ContactForm form={form} contact="PrimaryContact" />
                        </Form.Item>
                    </div>
                    <div className={styles.items}>
                        <Divider orientation="left" >Shipping address</Divider>
                        <Form.Item name={['ShippingAddress']} >
                            <AddressForm form={form} address="ShippingAddress" />
                        </Form.Item>
                    </div>
                    <div className={styles.items}>
                        <Divider orientation="left" >Billing Contact</Divider>
                        {showBillingContact &&
                            <Form.Item name="BillingContact">
                                <ContactForm form={form} contact="BillingContact" />
                            </Form.Item>

                        }
                        <Form.Item valuePropName="checked" name="BillingContactSameAsProduct">
                            <Checkbox onChange={onChangeContact} checked={!showBillingContact}>Same as primary contact</Checkbox>
                        </Form.Item>
                    </div>
                    <div className={styles.items}>
                        <Divider orientation="left" >Billing Address</Divider>
                        {showBillingAddress &&
                            <Form.Item name={['BillingAddress']} >
                                <AddressForm form={form} address={"BillingAddress"} />
                            </Form.Item>
                        }
                        <Form.Item valuePropName="checked" name="BillingAddressSameAsShipping">
                            <Checkbox onChange={onChangeAddress} checked={!showBillingAddress}>Same as shiping address</Checkbox>
                        </Form.Item>
                    </div>
                </div >
                {/* <Row>
                            <Col span={24}>
                                <Form.Item label='VAT Number:' name={"VatNumber"}>
                                    <Input placeholder="VAT Number (For EU business customers)" />
                                </Form.Item>
                            </Col>
                        </Row> */}
                <Divider />
                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={updating}>
                        Update Information
                    </Button>
                </Form.Item>
            </Form >
        </div>
    )
}

import { Alert, Row, Skeleton, Tabs } from 'antd';
import Meta from 'antd/lib/card/Meta';
import * as React from 'react';
import { useBillingModel } from '../../hooks/useBillingModel';
import { useAppDispatch } from "../../stores";
import { setPortalState } from '../../stores/portalReducer';
import { BillingModel, StripeCustomerAccount } from '../../types';
import { AccountBilling } from './accountBilling';
import { PaymentHistory } from './paymentHistory';

const { TabPane } = Tabs;

export const BillingsData: React.FC = () => {
    const dispatch = useAppDispatch();

    const { model, loading } = useBillingModel();

    const stripeAccounts = model?.StripeCustomerAccounts || [];
    const payments = model?.Payments || [];


    const onChange = (activeKey: string) => {
        const newModel = {
            ...model,
            SelectedCustomerAccount: activeKey
        }
        dispatch(
            setPortalState({
                billingModel: newModel as BillingModel,
            })
        );
    };

    const tabTitle = (account: StripeCustomerAccount) => {
        return <Meta
            style={{ display: "flex", alignItems: "center", width: '100%' }}
            title={account.Email}
            description={account.Description}
        />
    }

    return (
        <>
            <Skeleton loading={loading}>
                {stripeAccounts.length > 0 &&
                    <Tabs
                        hideAdd
                        onChange={onChange}
                        activeKey={model?.SelectedCustomerAccount}
                    >
                        {stripeAccounts.map(account => (
                            <TabPane tab={tabTitle(account)} key={account.Id}>
                                <AccountBilling account={account} loading={loading} />
                            </TabPane>
                        ))}
                    </Tabs>

                }
                {stripeAccounts.length === 0 && <>
                    <h3>Payment methods</h3>
                    <Alert
                        message="No credit card provided"
                        type="warning"
                    />
                </>}
            </Skeleton>

            <h3>Payment history</h3>
            <PaymentHistory loading={loading} items={payments} rowKey={"OrderId"} />
            <Row>
                &nbsp;
            </Row>
        </>
    );
}
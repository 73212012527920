import { FC, Suspense, useEffect } from 'react';
import { Layout } from 'antd';
import { useAppDispatch, useAppState } from '../../stores';
import SiderMenu from './SiderMenu';
import LayoutBanner from './LayoutBanner';
import { setPortalState } from '../../stores/portalReducer';
import { PageLoading } from '../components/PageLoading';
import styles from './style.module.scss';
import { Intercom } from '../components/Intercom';

const { Content } = Layout;
const WIDTH = 992;

const MainLayout: FC = ({ children }) => {
    const { collapsed } = useAppState(state => state);
    const dispatch = useAppDispatch();

    const toggle = () => {
        dispatch(
            setPortalState({
                collapsed: !collapsed
            })
        );
    };

    useEffect(() => {
        window.onresize = () => {
            const rect = document.body.getBoundingClientRect();
            const needCollapse = rect.width < WIDTH;
            dispatch(
                setPortalState({
                    collapsed: needCollapse
                })
            );
        };
    }, [dispatch]);

    return (
        <Layout className={styles.layout}>
            <SiderMenu collapsed={collapsed} handleOnCollapse={toggle} />
            <Layout className={styles.layout_wrapper}>
                <LayoutBanner
                    collapsed={collapsed}
                    handleOnCollapse={toggle}
                />
                <Content className={styles.content}>
                    <Suspense fallback={<PageLoading />}>
                        {children}
                    </Suspense>
                </Content>
            </Layout>
            {window.ac_env === "production" && <Intercom />}
        </Layout>
    );
};

export default MainLayout;

import { Divider, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Payment, PaymentItem } from "../../types";

import styles from './style.module.scss';
import moment from "moment";

type Props = {
    payment: Payment | undefined;
}

export const PaymentItems: React.FC<Props> = ({ payment }) => {
    const columns: ColumnsType<PaymentItem> = [
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            render: (text, record) => {
                return <>
                    <>{record.Description}</>
                    {record.HasTrialPeriod && <><br /><em>
                        ({moment(record.TrialPeriodStart).format('MMM DD, YYYY')} - {moment(record.TrialPeriodEnd).format('MMM DD, YYYY')})
                    </em>
                    </>}
                </>
            }
        },
        {
            title: 'Rate, USD',
            dataIndex: 'Rate',
            key: 'Rate',
            align: "right",
            width: '15%',
            render: val => <>{val < 0 ? "-" : ""}${(Math.abs(val)).toFixed(2)}</>,
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            align: "right",
            width: '15%',
        },
        {
            title: 'Amount, USD',
            dataIndex: 'Amount',
            key: 'Amount',
            align: "right",
            width: '15%',
            render: val => <>{val < 0 ? "-" : ""}${(Math.abs(val)).toFixed(2)}</>,
        },
    ];

    return (
        <>
            {!!payment && <>

                <Table<PaymentItem>
                    className={styles.upcoming_table}
                    dataSource={payment.Items}
                    columns={columns}
                    rowKey={"OrderItemId"}
                    pagination={false}
                    summary={() => {
                        return (
                            <>
                                <Table.Summary fixed >
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={2} className={styles.summary_cell} align={"right"} ><strong>Subtotal</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className={styles.summary_cell} align={"right"}>{payment.Subtotal < 0 ? "-" : ""}${(Math.abs(payment.Subtotal)).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    {payment.Discount > 0 &&
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} colSpan={2} className={styles.summary_cell} align={"right"} >Discount</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} className={styles.summary_cell} align={"right"}>-${payment.Discount.toFixed(2)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    }
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={2} className={styles.summary_cell} align={"right"} >GST/HST  {(payment.Tax !== 0 && payment.Tax_Percent === 0) ? <></> : <>({payment.Tax_Percent}%)</>}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className={styles.summary_cell} align={"right"} >{payment.Tax < 0 ? "-" : ""}${(Math.abs(payment.Tax)).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={2} className={styles.summary_cell} align={"right"}> <strong> Grand Total</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className={styles.summary_cell} align={"right"} > {payment.GrandTotal < 0 ? "-" : ""}${(Math.abs(payment.GrandTotal)).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={4} className={styles.summary_cell}>
                                            <span>Payment Type: </span> <Typography.Text strong>{payment.PaymentType}</Typography.Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            </>
                        );
                    }}
                />
                < Divider />
            </>}
        </>
    );
}

import { Tooltip } from 'antd';
import { FC, } from 'react';
import bv_logo from '../../../assets/bv-logo.svg';
import ep_logo from '../../../assets/ep-logo.svg';
import styles from './style.module.scss';

export const Logos: FC = () => {

    const bv_tip = <span><strong>Birdview PSA</strong> is a professional services automation platform built for organizations that&nbsp;want to better manage their entire delivery lifecycle. It includes all of the features and functionality of Easy Projects, plus an added layer of advanced functionality and financial tools for billing clients and tracking revenue and&nbsp;profit.</span>
    const ep_tip = <span><strong>Easy Projects</strong> is Work and Project Management Software that is designed to help teams of all sizes collaborate more effectively, stay on budget and deliver projects&nbsp;faster.</span>
    return (
        <div className={styles.flex}>
            <Tooltip placement="bottom" title={ep_tip}>
                <div className={styles.logo_block}>
                    <a href="https://easyprojects.net/">
                        <img className={styles.logo_image_ep} src={ep_logo} alt="EasyProjects" />
                    </a>
                </div>
            </Tooltip>
            <Tooltip placement="bottom" title={bv_tip}>
                <div className={styles.logo_block}>

                    <a href="https://birdviewpsa.com">
                        <img className={styles.logo_image_bv} src={bv_logo} alt="Birdview" />
                    </a>
                </div>
            </Tooltip>
        </div >
    )
}
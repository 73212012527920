import { Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Invoice, InvoiceLineItem } from "../../types";

import styles from './style.module.scss';

type Props = {
    loading: boolean;
    upcoming: Invoice | undefined;
}

export const AccountUpcomingInvoice: React.FC<Props> = ({ upcoming, loading }) => {
    const columns: ColumnsType<InvoiceLineItem> = [
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Date',
            dataIndex: 'Period',
            key: 'Period',
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            align: "right",
            render: val => <>${(val).toFixed(2)}</>,
        },
    ];

    return (
        <>
            {!!upcoming && <>
                <h3>Upcoming invoice</h3>
                <span>
                    This is a preview of the invoice that will be billed on <strong>{moment(upcoming.Created).format('MMM DD, YYYY')}</strong>. It may change if the subscription is updated.
                </span>
                < Divider />
                <Table<InvoiceLineItem>
                    className={styles.upcoming_table}
                    loading={loading}
                    dataSource={upcoming.Lines}
                    columns={columns}
                    rowKey={"Id"}
                    pagination={false}
                    summary={() => {
                        return (
                            <>
                                <Table.Summary fixed >
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}><strong>Subtotal</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"}>${upcoming.Subtotal.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    {upcoming.DiscountValue !== 0 && <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}>Discount ({upcoming.DiscountDescription})</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"}> -${upcoming.DiscountValue.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    }
                                    {upcoming.Tax > 0 && <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}>{upcoming.TaxDescription}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"} > ${upcoming.Tax.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    }
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}><strong>Total</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"} > ${upcoming.Total.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    {upcoming.StartingBalance > 0 && <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}><strong>Starting customer balance</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"} >${upcoming.StartingBalance.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                    }
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} className={styles.summary_cell}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className={styles.summary_cell}><strong>Amount Due</strong></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} className={styles.summary_cell} align={"right"} >${upcoming.AmountDue.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            </>
                        );
                    }}
                />
                < Divider />
            </>}
        </>
    );
}
import React, { ChangeEvent, useState } from 'react';
import { Modal, Switch, Form, Input, Row, Col, Button, Typography } from 'antd';

import { updateBillingInfoActionAsync } from '../../stores/portalReducer';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import { useAppDispatch } from '../../stores';
import { CountryDropdown, StateDropdown } from '../components/CountryStates';

const CARD_OPTIONS = {
    hidePostalCode: true,
    style: {
        base: {
            fontSize: window.innerWidth < 450 ? '14px' : '18px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Montserrat, sans-serif',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};
type BillingInfo = {
    address_line1: string,
    address_line2: string,
    address_city: string,
    address_zip: string,
    address_country: string,
    address_state: string,
    name: string,
}

type UpdateCardFormProps = {
    onSubmit: (event: any) => void;
    handleBlur: () => any;
    handleFieldChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleCardFieldChange: (event: StripeCardElementChangeEvent) => void;
    billing: BillingInfo,
    selectProvince: (value: string) => void;
    selectCountry: (value: string) => void;

}



export const UpdateCardForm: React.FC<UpdateCardFormProps> = ({ onSubmit, handleBlur, handleFieldChange, handleCardFieldChange, billing, selectProvince, selectCountry }) => {

    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [form] = Form.useForm();

    return (
        <Form form={form} onFinish={onSubmit} layout="vertical" >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label={`Card Information`} required>
                        <CardElement className="ant-input"
                            onBlur={handleBlur}
                            onChange={handleCardFieldChange}
                            options={CARD_OPTIONS}
                        // onReady={this.props.cardRef}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item >
                        <Switch defaultChecked={showAdditionalInfo} onChange={setShowAdditionalInfo} checkedChildren="Hide additional options" unCheckedChildren="Show additional options" />
                    </Form.Item>
                </Col>
            </Row>
            {showAdditionalInfo &&
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={`Cardholder name`} name={"name"}>
                                <Input
                                    name={"name"}
                                    onBlur={handleBlur}
                                    placeholder="Cardholder"
                                    onChange={handleFieldChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={5}>Billing Address</Typography.Title>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={`Country`} name={"address_country"}>
                                <CountryDropdown
                                    name={"address_country"}
                                    priorityOptions={["US", "CA"]}
                                    value={billing.address_country}
                                    valueType={'short'}
                                    onChange={selectCountry}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Address line 1" name={"address_line1"}>
                                <Input name={"address_line1"} placeholder="Address line 1" onChange={handleFieldChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Address line 2" name={"address_line2"}>
                                <Input name={"address_line2"} placeholder="Address line 2" onChange={handleFieldChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="City" name={"address_city"}>
                                <Input name={"address_city"} placeholder="City" onChange={handleFieldChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="State / Province" name={"address_state"}>
                                <StateDropdown
                                    name={"address_state"}
                                    value={billing.address_state}
                                    country={billing.address_country}
                                    defaultOptionLabel="Select state / province"
                                    countryValueType={'short'}
                                    valueType={'short'}
                                    onChange={selectProvince}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Zip / Postal code" name={"address_zip"}>
                                <Input name={"address_zip"} placeholder="Zip / Postal code" onChange={handleFieldChange} />
                            </Form.Item>
                        </Col>
                    </Row>

                </>
            }

        </Form >
    );
}





type UpdateBillingInfoModalProps = {
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
    accountId: string;
}

export const UpdateBillingInfoModal: React.FC<UpdateBillingInfoModalProps> = ({ visible, onCancel, onOk, accountId }) => {
    const dispatch = useAppDispatch();

    const [error, setError] = useState(null as StripeError | null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [billingInfo, setBillingInfo] = useState({
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_zip: '',
        address_country: '',
        address_state: '',
        name: '',
    });

    const stripe = useStripe();
    const elements = useElements();

    const handleCardFieldChange = event => {
        setCardComplete(event.complete);
    }
    const handleBlur = () => {
        validateForm();
    };

    const handleFieldChange = event => {
        setBillingInfo({
            ...billingInfo,
            [event.target.name]: event.target.value
        });
    }

    const validateForm = () => {
        return stripe && cardComplete;
    };

    const selectCountry = (value: string) => {
        setBillingInfo({
            ...billingInfo,
            address_country: value,
            address_state: ''
        });
    };

    const selectProvince = (value: string) => {
        setBillingInfo({
            ...billingInfo,
            address_state: value
        });
    };

    const handleSubmit = async event => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const card = elements.getElement(CardElement);

        if (!card) return;

        const { token, error } = await stripe.createToken(card, billingInfo);

        if (error) {
            setError(error);
        } else {
            // Send the token to server.
            if (!token) return;

            await stripeTokenHandler(token?.id || "");
        }

        setProcessing(false);
    };

    const stripeTokenHandler = async (token: string) => {

        const result = await dispatch(updateBillingInfoActionAsync({
            ...billingInfo,
            token: token,
            accountId
        }));

        if (result === true) {
            onOk();
        }
    };

    return (
        <Modal centered
            width={500}
            bodyStyle={{ padding: '32px 40px 48px' }}
            destroyOnClose={true}
            title={"Update Billing Information"}
            visible={visible}
            onCancel={onCancel}
            footer={
                [
                    <Button key="Cancel" onClick={onCancel} >Cancel</Button>,
                    <Button key="Update" type="primary" onClick={handleSubmit} loading={processing}
                        disabled={!validateForm()}
                    >
                        Update
                    </Button>
                ]}
        >
            <>
                <UpdateCardForm
                    onSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    handleCardFieldChange={handleCardFieldChange}
                    handleFieldChange={handleFieldChange}
                    billing={billingInfo}
                    selectCountry={selectCountry}
                    selectProvince={selectProvince}

                />
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
        </Modal >
    );
}

const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);




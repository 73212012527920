import { Divider, Table, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import * as enums from "../../constants/enums";
import { Subscription } from "../../types";
import { isExtraStorage, isHostedClient, isInhouseClient } from "../../utils";
const { Text } = Typography;

type Props = {
    loading: boolean;
    rowKey: string;
    items: Subscription[];
    clientLicenseType?: number;
}

export const CancelledSubscriptions: React.FC<Props> = ({ loading, items, rowKey, clientLicenseType }) => {
    const columns: ColumnsType<Subscription> = [
        {
            title: "Created on",
            dataIndex: 'FirstOrderDate',
            key: 'FirstOrderDate',
            width: 150,
            render: val => <>{moment(val).format('MM/DD/YYYY')}</>,
        },
        {
            title: <>{isHostedClient(clientLicenseType) ? "Users / Storage" : "Users"}</>,
            dataIndex: 'NumberOfUsers',
            key: 'NumberOfUsers',
            align: "right",
            width: 150,
            render: (text, record) => {
                if (isExtraStorage(record.LicenseType)) {
                    return <> {record.ExtraStorage} Gb</>
                } else {
                    return <>
                        {record.IsRestrictedLicense &&
                            <Tooltip placement="top" color={"blue"}
                                title="Include additional Timekeepers, Contributor and other discounted licenses.">
                                <Tag color={"purple"}>RESTRICTED</Tag>
                            </Tooltip>}
                        {record.NumberOfUsers}
                    </>
                }
            },
        },
        {
            title: "Product",
            dataIndex: 'ProductName',
            key: 'ProductName',
            width: 300,
            render: (text, record) => {
                const version = isExtraStorage(record.LicenseType) ? <></> : <Tag >{record.Version}</Tag>;
                return (
                    <> {record.ProductName} {enums.getEdition(record.Edition)}: {version}</>
                );
            },
        },
        {
            title: "Type",
            dataIndex: 'LicenseType',
            key: 'LicenseType',
            width: '20%',
            render: (text, record) => {
                return (
                    <>  {enums.getEnumName(record.LicenseType, enums.LicenseType)} {record.LicenseRenewal === 3 && <Text style={{ color: "blue" }}>(FREE)</Text>}</>
                );
            },
        },
        {
            title: "Cancelled on",
            dataIndex: 'CancellationDate',
            key: 'CancellationDate',
            render: val => <>{val ? moment(val).format('MM/DD/YYYY') : ""}</>,
        },
    ];
    return (
        <>
            <Divider orientation="left" >Cancelled {isInhouseClient(clientLicenseType) ? "Licenses" : "Subscriptions"}</Divider>
            <Table<Subscription>
                bordered
                loading={loading}
                dataSource={items}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
            />
        </>);
}
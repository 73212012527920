import { StripeCustomerAccount } from "../../types"
import { AccountUpcomingInvoice } from "./accountUpcomingInvoice";
import { PaymentMethods } from "./paymentMethods"

type Props = {
    account: StripeCustomerAccount,
    loading: boolean;
}
export const AccountBilling: React.FC<Props> = ({ account, loading }) => {
    return (
        <>
            {account.Card && <PaymentMethods loading={loading} card={account.Card} />}
            <AccountUpcomingInvoice loading={loading} upcoming={account.Upcoming} />
        </>
    );
}
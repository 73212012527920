import { Button, Table, TableColumnsType, Tag } from "antd";
import moment from "moment";
import { useState } from "react";
import { Payment } from "../../types";
import { PaymentDetails } from "./paymentDetails";

type Props = {
    loading: boolean;
    rowKey: string;
    items: Payment[];
}

export const PaymentHistory: React.FC<Props> = ({ loading, items, rowKey }) => {
    const columns: TableColumnsType<Payment> = [
        {
            title: "Order #",
            dataIndex: 'OrderNumber',
            key: 'OrderNumber',
            width: 200,
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => openPaymentDetails(record)}>{record.OrderNumber} </Button>
                );
            },
        },
        {
            title: "Order Date",
            dataIndex: 'OrderDate',
            key: 'OrderDate',
            align: "center",
            width: 150,
            render: val => <>{moment(val).format('MM/DD/YYYY')}</>,
        },
        {
            title: "Details",
            dataIndex: 'Description',
            key: 'Description',
            render: (text, record) => {
                const orderNotes = record.OrderNotes === "REFUND" ? <Tag color="red">{record.OrderNotes}</Tag> :
                    record.OrderNotes === "PRORATE" ? <Tag color="blue">{record.OrderNotes}</Tag> : <></>
                return (
                    <> <span>{record.Description} <span style={{ float: "right" }}>{orderNotes}</span></span>
                        {record.Comments &&
                            <i>
                                <br />
                                {record.Comments}
                            </i>
                        }
                    </>
                );
            },
        },
        {
            title: "Amount",
            dataIndex: 'Amount',
            key: 'Amount',
            width: 150,
            align: "right",
            render: val => <>{val < 0 ? "-" : ""}${(Math.abs(val)).toFixed(2)}</>,
        },
        {
            title: "Tax",
            dataIndex: 'Tax',
            key: 'Tax',
            width: 150,
            align: "right",
            render: val => <>{val < 0 ? "-" : ""}${(Math.abs(val)).toFixed(2)}</>,
        },
        {
            title: "Grand Total",
            dataIndex: 'GrandTotal',
            key: 'GrandTotal',
            width: 150,
            align: "right",
            render: val => <>{val < 0 ? "-" : ""}${(Math.abs(val)).toFixed(2)}</>,
        },

    ];

    const [showDetails, setShowDetails] = useState(false);
    const [payment, setPayment] = useState(undefined as Payment | undefined);

    const handleCloseDetails = () => {
        setShowDetails(false);
        setPayment(undefined);
    };

    const openPaymentDetails = (record: Payment) => {
        setShowDetails(true);
        setPayment(record);
    }

    const
        onRow = (record, rowIndex) => {
            return {
                onClick: (event) => openPaymentDetails(record),
            };
        }
    return (
        <>
            <Table<Payment>
                bordered
                loading={loading}
                dataSource={items}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
                onRow={onRow}
                summary={() => {
                    const amountTotals = items.reduce((a, { Amount }) => a + Amount, 0);
                    const taxTotals = items.reduce((a, { Tax }) => a + Tax, 0);
                    const grandTotalTotals = items.reduce((a, { GrandTotal }) => a + GrandTotal, 0);
                    return (
                        <>
                            <Table.Summary fixed >
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} align={"right"} ><strong>Totals:</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} align={"right"}><strong>{amountTotals < 0 ? "-" : ""}${(Math.abs(amountTotals)).toFixed(2)}</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align={"right"}><strong>{taxTotals < 0 ? "-" : ""}${(Math.abs(taxTotals)).toFixed(2)}</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align={"right"}><strong>{grandTotalTotals < 0 ? "-" : ""}${(Math.abs(grandTotalTotals)).toFixed(2)}</strong></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        </>
                    );
                }}
            />
            {payment && <PaymentDetails
                showDetails={showDetails}
                onClose={handleCloseDetails}
                data={payment}
            />
            }
        </>);
}
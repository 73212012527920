import React, { useState } from 'react';
import { Typography, Divider, Form, Checkbox, Input, Button, Space, Descriptions, Col, Card } from 'antd';
import { EditOutlined, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { useClientModel } from '../../hooks/useClientModel';
import { Client, Contact } from '../../types';
import { PageLoading } from '../components/PageLoading';
import styles from './style.module.scss';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../stores';
import { updateEmailNotificationsAsync } from '../../stores/portalReducer';
import { isHostedClient } from '../../utils';

export const EmailSettings: React.FC = () => {

    const dispatch = useAppDispatch();
    const { model, loading } = useClientModel();
    const [updating, setUpdating] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const [subscribed, setSubscribed] = useState(false);
    const [showWeekly, setShowWeekly] = useState(false);

    React.useEffect(() => {
        if (!loading) {
            setSubscribed(model?.Client.IsSubscribed || false);
             // hide weekly account review report section
            setShowWeekly(false); // isHostedClient(model?.ClientLicenseType));
        }
    }, [loading, model]);

    if (loading) {
        return <PageLoading />
    }



    const copyContactFrom = (key: number, contact: Contact | undefined) => {
        const { Subscribers } = form.getFieldsValue();

        const newSubscribers = [...Subscribers];
        const item = newSubscribers[key];
        const newItem = {
            Name: contact?.Name,
            Email: contact?.Email,
        }
        newSubscribers.splice(key, 1, {
            ...item,
            ...newItem,
        });

        form.setFieldsValue({
            Subscribers: newSubscribers
        });
        validateForm();
    }

    const editContact = event => {
        history.push('/portal/companyProfile');
        event.stopPropagation();
    }

    const validateForm = async () => {
        await form.validateFields();
    }
    const onFinished = async (client: Client) => {
        validateForm();

        setUpdating(true);
        await dispatch(updateEmailNotificationsAsync(client));
        setUpdating(false);
    };

    const onChange = (e: any) => {
        setSubscribed(e.target.checked);
    }

    const validateDuplicate = (rule: any, value: any) => {
        if (value) {
            const { Subscribers } = form.getFieldsValue();
            const isValid = Subscribers.filter(x => x && x.Email === value.trim()).length <= 1;
            if (isValid) {
                return Promise.resolve();
            } else {
                return Promise.reject(`Duplicate email. Please enter unique email`);
            }
        }
        else {
            return Promise.resolve();
        }
    };

    return (
        <div >
            <Card
                title={<>
                    <Checkbox checked disabled >Primary Contact</Checkbox>
                    <EditOutlined title="Update contact" onClick={editContact} style={{ marginLeft: 50 }} />
                </>}
                bordered={false}
            >
                <Space wrap align={"start"} size={"large"}>
                    <div className={styles.form_items}>
                        <Descriptions column={1} >
                            <Descriptions.Item label={<Col style={{ width: 50, textAlign: "right" }}>Name</Col>}>{model?.Client.PrimaryContact.Name}</Descriptions.Item>
                            <Descriptions.Item label={<Col style={{ width: 50, textAlign: "right" }}>Email</Col>}>{model?.Client.PrimaryContact.Email}</Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className={styles.form_notes}>
                        <Typography.Text italic>General notifications: product tips & tricks, maintenance notices, product updates, etc.  </Typography.Text><br />
                        <Typography.Text italic>Frequency: occasionaly  </Typography.Text>
                    </div>
                </Space>
            </Card>
            <Card
                title={<>
                    <Checkbox checked disabled >Billing Contact</Checkbox>
                    <EditOutlined title="Update contact" onClick={editContact} style={{ marginLeft: 50 }} />
                </>
                }
                bordered={false}
            >
                <Space wrap align={"start"} size={"large"}>
                    <div className={styles.form_items}>
                        <Descriptions column={1} >
                            <Descriptions.Item label={<Col style={{ width: 50, textAlign: "right" }}>Name</Col>}>{model?.Client.BillingContact.Name}</Descriptions.Item>
                            <Descriptions.Item label={<Col style={{ width: 50, textAlign: "right" }}>Email</Col>}>{model?.Client.BillingContact.Email}</Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div className={styles.form_notes}>
                        <Typography.Text italic>Upcoming charges, transaction receipts, etc. </Typography.Text><br />
                        <Typography.Text italic>Frequency: occasionaly  </Typography.Text>
                    </div>
                </Space>
            </Card>
            {showWeekly && <Form<Client>
                form={form}
                requiredMark={false}
                onFinish={onFinished}
                initialValues={model?.Client} >

                <Card
                    title={<Form.Item name="IsSubscribed" valuePropName="checked" style={{ margin: 0 }}>
                        <Checkbox onChange={onChange}>Weekly usage report</Checkbox>
                    </Form.Item>}
                    bordered={false}
                >
                    {subscribed &&
                        <Space wrap align={"start"} size={"large"}>
                            <Form.List name="Subscribers">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key}>
                                                <Form.Item
                                                    {...restField}
                                                    className={styles.form_items}
                                                    label={<Col style={{ width: 50, }}>Name</Col>}
                                                    name={[name, 'Name']}
                                                    rules={[
                                                        { required: true, whitespace: true, message: 'Name is required!' },
                                                    ]}
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    className={styles.form_items}
                                                    label={<Col style={{ width: 50, }}>Email</Col>}
                                                    name={[name, 'Email']}
                                                    rules={[{
                                                        type: 'email',
                                                        message: 'The input is not valid e-mail!',
                                                    },
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Email is required!'
                                                    },
                                                    {
                                                        validator: validateDuplicate,
                                                    },
                                                    ]}>
                                                    <Input placeholder="Email" onBlur={() => validateForm()} />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Space wrap size="small" split={<Divider type="vertical" />}>
                                                        <Button type="link" onClick={() => copyContactFrom(name, model?.Client.PrimaryContact)}>Copy from Primary Contact</Button>
                                                        <Button type="link" onClick={() => copyContactFrom(name, model?.Client.BillingContact)}>Copy from Billing Contact</Button>
                                                        {fields.length > 1 && <DeleteTwoTone twoToneColor="#eb2f96" title="Remove contact" onClick={() => remove(name)} />}
                                                    </Space>
                                                </Form.Item>
                                                <Divider />
                                            </div>

                                        ))}
                                        {fields.length < 3 && <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add contact
                                            </Button>
                                        </Form.Item>
                                        }
                                    </>
                                )}
                            </Form.List>
                            <div className={styles.form_notes}>
                                <Typography.Text italic>Personalized product usage statistics </Typography.Text><br />
                                <Typography.Text italic>Frequency: weekly  </Typography.Text>
                            </div>
                        </Space>
                    }
                </Card>

                <Divider />
                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={updating}>
                        Save changes
                    </Button>
                </Form.Item>
            </Form>
            }
        </div>
    );
}

import * as React from "react";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import styles from './style.module.scss';
import { GetUsernameAvatar } from '../components/UserAvatar';
import { SiderMenuProps } from "./SiderMenu";
import { logoutAsync } from "../../stores/portalReducer";
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppState } from "../../stores";
import { SiteName } from "./SiteName";

const { Header } = Layout;
const { SubMenu } = Menu;

type Action = 'myProfile' | 'logout';

const LayoutBanner: React.FC<SiderMenuProps> = ({ collapsed, handleOnCollapse }) => {
    const getCollapseIcon = () => {
        return (
            <>
                {collapsed ? <MenuUnfoldOutlined onClick={handleOnCollapse} className={styles.trigger} /> :
                    <MenuFoldOutlined onClick={handleOnCollapse} className={styles.trigger} />}
            </>
        );
    };
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { currentUser } = useAppState(state => state);

    const onActionClick = async (action: Action) => {
        switch (action) {
            // case 'myProfile':
            //     return;
            case 'logout':
                await dispatch(logoutAsync()).then(
                    (res) => {
                        !!res && history.push('/portal/login');
                    }
                );
                return;
        }
    };

    if (!currentUser) {
        return null;
    }
    return (
        <Header>
            <div
                style={{
                    float: 'left',
                    width: '100%',
                    alignSelf: 'center',
                    display: 'flex',
                }}
            >
                {window.innerWidth > 992 && getCollapseIcon()}
                <SiteName />
            </div>

            <Menu mode="horizontal" className={styles.menu} >
                <SubMenu title={GetUsernameAvatar()} key="settings">
                    {/* <Menu.Item key="setting:1">
                        <span onClick={() => onActionClick('myProfile')}>
                            <UserOutlined className={styles.menu_icon} />
                            Profile
                        </span>
                    </Menu.Item> */}
                    <Menu.Item key="setting:2">
                        <span onClick={() => onActionClick('logout')}>
                            <LogoutOutlined className={styles.menu_icon} />
                            Logout
                        </span>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Header>
    );
}

export default LayoutBanner;